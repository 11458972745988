export const basicComponents = [
  {
    type: 'input',
    icon: 'icon-wenbenkuang1',
    options: {
      label: '单行文本',
      model: '',
      width: 24,
      defaultValue: '',
      isRep: false,
      isSearch: false,
      required: false,
      tag: 'DoString',
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      des: '一般用于录入短语文字'
    }
  },
  {
    type: 'textarea',
    icon: 'icon-duohangwenben',
    options: {
      label: '多行文本',
      model: '',
      width: 24,
      defaultValue: '',
      isRep: false,
      // isSearch: false,
      required: false,
      tag: 'DoString',
      disabled: false,
      checkContext: false,
      pattern: '',
      placeholder: '',
      help: '',
      des: '一般用于录入大段多行文字,如:说明内容'
    }
  },
  {
    type: 'input',
    icon: 'icon-xiaoshu',
    options: {
      label: '小数',
      model: '',
      width: 24,
      defaultValue: '0',
      isRep: false,
      // isSearch: false,
      required: false,
      field: '',
      tag: 'DoFloat',
      unit: '',
      isNum: 2, //小数位数
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      des: '一般用于录入金额费用数字'
    }
  },
  {
    type: 'input',
    icon: 'icon-zhengshu',
    options: {
      label: '整数',
      model: '',
      width: 24,
      defaultValue: '0',
      isRep: false,
      isSearch: false,
      required: false,
      field: '',
      unit: '',
      isIntNum: 8, //整数位数
      tag: 'DoNumber',
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      des: '一般用于录入数字,如年龄'
    }
  },
  {
    type: 'date',
    icon: 'icon-riqi',
    options: {
      label: '日期',
      model: '',
      defaultValue: '',
      isSearch: false,
      disabled: false,
      getNowDate: false,
      setDays: [
        {
          model: '',
          day: 1,
          id: ''
        }
      ],
      placeholder: '请选择日期',
      width: 24,
      tag: 'DoDate',
      type: 'date',
      format: 'yyyy-MM-DD',
      required: false,
      help: '',
      des: '一般用于录入时间单位,如出生日期'
    }
  },
  {
    type: 'switch',
    icon: 'icon-kaiguan',
    options: {
      label: '开关',
      defaultValue: false,
      model: '',
      width: 24,
      isSearch: false,
      required: false,
      tag: 'DoSwitch',
      disabled: false,
      pattern: '',
      placeholder: '',
      help: '',
      des: '一般用于录入二选一项目,如:性别'
    }
  },
  {
    type: 'radio',
    icon: 'icon-danxuan',
    options: {
      label: '单选',
      model: '',
      width: 24,
      defaultValue: '',
      isSearch: false,
      tag: 'DoRadio',
      required: false,
      disabled: false,
      optionType: '2',
      options: [
        {
          value: '',
          label: ''
        }
      ],
      help: '',
      des: '一般用于录入多选一项目,如:职业类别'
    }
  },
  {
    type: 'checkbox',
    icon: 'icon-duoxuan1',
    options: {
      label: '多选',
      model: '',
      width: 24,
      defaultValue: [],
      isSearch: false,
      tag: 'DoCheck',
      required: false,
      disabled: false,
      optionType: '2',
      options: [
        {
          value: '',
          label: ''
        }
      ],
      help: '',
      des: '一般用于录入多选项目,如:爱好项目'
    }
  },
  {
    type: 'image',
    icon: 'icon-tupian',
    options: {
      label: '图片',
      defaultValue: [],
      tag: 'DoImage',
      fileNum: 5,
      fileSize: 10,
      required: false,
      disabled: false,
      support: ['.png', '.jpg', '.jpeg', '.gif'],
      width: 24,
      help: '',
      des: '一般用于上传图片,如：商品图片'
    }
  },
  {
    type: 'file',
    icon: 'icon-shangchuan',
    options: {
      label: '文件',
      defaultValue: [],
      tag: 'DoFile',
      fileNum: 5,
      required: false,
      fileSize: 10,
      disabled: false,
      // support: ['.word', '.xls', '.txt'],
      width: 24,
      help: '',
      des: '一般用于上传附件,如：说明书'
    }
  }
]

export const advancedComponents = [
  {
    type: 'code',
    icon: 'icon-Field-number',
    options: {
      label: '编号',
      defaultValue: '',
      placeholder: '由系统自动生成',
      isGen: '', // 后台根据处判断是否生成
      prefix: 'H',
      isSearch: false,
      width: 24,
      tag: 'DoCode',
      help: '',
      length: 10,
      des: '一般用于产生记录的唯一编号,如:工号,订单号'
    }
  },
  {
    type: 'log',
    icon: 'icon-zijinrizhi',
    options: {
      label: '日志',
      defaultValue: '',
      placeholder: '请输入日志内容',
      // isSearch: false,
      width: 24,
      tag: 'DoLog',
      help: '',
      des: '保存录入内容为历史记录,不能修改或删除'
    }
  },
  {
    type: 'slider',
    icon: 'icon-icon_huakuai',
    options: {
      label: '滑块',
      defaultValue: 0,
      // isSearch: false,
      disabled: false,
      tag: 'DoSlider',
      width: 24,
      max: 100,
      min: 0,
      step: 1,
      required: false,
      help: '',
      des: '一般用于录入固定范围的值 如:放大倍数'
    }
  },
  {
    type: 'rate',
    icon: 'icon-pingfen',
    options: {
      label: '评分',
      defaultValue: 0,
      isSearch: false,
      width: 24,
      count: 5,
      disabled: false,
      tag: 'DoRate',
      required: false,
      help: '',
      des: '一般用于商品的评价打分'
    }
  },
  {
    // isShow: true,
    type: 'table',
    icon: 'icon-biaoge',
    options: {
      label: '子表',
      // width: 24,
      defaultValue: [],
      sourceType: 'custom', //custom：自定义 rel：关联选择
      hideFields: [], //在关联其他表单的情况下设置可以隐藏的字段
      formID: '',
      tables: {
        elements: []
      },
      tag: 'DoTable',
      help: '',
      des: '一般用于录入一对多明细记录'
    }
  },
  // {
  //     type: 'task',
  //     icon: 'icon-duoxuankuang1',
  //     options: {
  //         tag: 'DoTask',
  //         label: '任务',
  //         defaultValue: '',
  //         width: 24,
  //         help: '',
  //         des: '记录任务完成情况'
  //     }
  // },
  {
    isShow: true,
    type: 'formula',
    icon: 'icon-bianma',
    options: {
      label: '公式',
      width: 24,
      tag: 'DoFormula',
      unit: '',
      field: '',
      formula: '', //公式
      isNum: 2, //小数位数
      fun: 'CUSTOM()', //函数名
      help: '',
      des: '一般用于实时计算相关数据'
    }
  },
  {
    isShow: true,
    type: 'rqcode',
    icon: 'icon-yingyong3',
    options: {
      label: '二维码',
      width: 24,
      tag: 'DoRqcode',
      url: '',
      help: '',
      des: '一般用于扫码跳转链接'
    }
  },
  {
    // isShow: true,
    type: 'alert',
    icon: 'icon-iconfontzhizuobiaozhunbduan19',
    options: {
      label: '预警提示',
      model: '',
      defaultValue: '2021-10-24',
      width: 24,
      showDay: false,
      field: '',
      warnVal: [
        {
          title: '三级预警',
          value: 14,
          color: '#3eaf7c'
        },
        {
          title: '二级预警',
          value: 7,
          color: '#fadb14'
        },
        {
          title: '一级预警',
          value: 3,
          color: '#fa541c'
        }
      ],
      tag: 'DoAlert',
      help: '',
      des: '一般用于用颜色提示用户处理快到期数据'
    }
  },
  {
    isShow: true,
    type: 'strCheck',
    icon: 'icon-yulan',
    options: {
      label: '图像识别',
      disabled: false,
      tag: 'DoStrCheck',
      // comModel: '',
      type: ['通用场景', '通用文字识别（高精度版）'],
      width: 24,
      help: '',
      des: '上传图片进行文字识别'
    }
  },
  {
    type: 'sign',
    icon: 'icon-dianziqianmingx',
    options: {
      label: '签名',
      defaultValue: [],
      disabled: false,
      tag: 'DoSign',
      // width: 24,
      required: false,
      help: '',
      des: '签名'
    }
  },
  {
    type: 'select',
    icon: 'icon-biaodanzujian-xialakuang',
    options: {
      label: '文本下拉框',
      model: '',
      width: 24,
      defaultValue: '',
      isSearch: false,
      tag: 'DoSelect',
      required: false,
      disabled: false,
      optionType: '2',
      options: [
        {
          value: '',
          label: ''
        }
      ],
      relSelect: [], //联动组件
      help: '',
      des: '一般用于少量数据的选择'
    }
  },
  {
    type: 'selectData',
    icon: 'icon-yinyong1',
    options: {
      label: '关联下拉框',
      tag: 'DoSelectData',
      model: '',
      defaultValue: [],
      isSearch: false,
      required: false,
      disabled: false,
      appUuid: '', //绑定的应用uuid
      formUuid: '', //绑定的表单uuid
      showField: '', //显示字段
      valueField: '', //取值字段
      assists: [], //辅助显示字段,最多三个
      relVals: [], //关联取值 值：{field: '', com: ''}
      compares: [
        {
          //取值条件
          comField: '', //数据源表单的某字段
          symbol: '=', //表示运算符号 大于 等于 小于...
          valueField: '', //具体判断的值
          model: '' //这个关联的值来自那个字段（fdxx）
        }
      ],
      relSelect: [], //联动组件
      isTree: false, //是否未树形
      width: 24,
      help: '',
      des: '可设置数据源，并进行级联选择'
    }
  },
  {
    type: 'collect',
    icon: 'icon-fenge',
    options: {
      label: '关联数据',
      tag: 'DoCollect',
      model: '',
      defaultValue: [],
      appUuid: '', //绑定的应用uuid
      formUuid: '', //绑定的表单uuid
      viewId: '', //绑定的视图id
      compares: [
        {
          //取值条件
          comField: '', //数据源表单的某字段
          symbol: '=', //表示运算符号 大于 等于 小于...
          valueField: '' //具体判断的值
        }
      ],
      help: '',
      des: '可选取关联表单中的数据，或通过过滤条件直接查询数据'
    }
  },
  {
    type: 'flows',
    icon: 'icon-web-icon-',
    options: {
      label: '关联流程',
      tag: 'DoFlows',
      model: '',
      defaultValue: [],
      help: '',
      des: '可根据分类取选取流程数据'
    }
  },
  {
    isShow: true,
    type: 'input',
    icon: 'icon-yincang2',
    options: {
      label: '隐藏数据',
      model: '',
      width: 24,
      defaultValue: '',
      tag: 'DoHidden',
      des: '隐藏数据，此字段在填写表单时不可见'
    }
  },
  {
    // isShow: true,
    type: 'wordTemplate',
    icon: 'icon-buju',
    options: {
      label: '文档模板',
      model: '',
      width: 24,
      defaultValue: '',
      templates: [],
      isSign: false, //是否生成公章
      outType: '1',
      tag: 'DoWordTemplate',
      des: '根据文档模板生成数据报告'
    }
  }
]

export const relationComponents = [
  {
    type: 'input',
    icon: 'icon-zhanghao1',
    options: {
      label: '姓名',
      model: '',
      width: 24,
      defaultValue: '',
      isRep: false,
      isSearch: false,
      isSpell: true,
      required: false,
      tag: 'DoName',
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      des: '一般用于人员信息的姓名'
    }
  },
  {
    type: 'input',
    icon: 'icon-dianhua1',
    options: {
      label: '手机号',
      model: '',
      width: 24,
      defaultValue: '',
      isRep: false,
      isSearch: false,
      required: false,
      tag: 'DoPhone',
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      des: '一般用于人员信息的手机,移动端支持拨打'
    }
  },
  {
    type: 'input',
    icon: 'icon-shenfenzheng',
    options: {
      label: '身份证',
      model: '',
      width: 24,
      defaultValue: '',
      isRep: false,
      isSearch: false,
      required: false,
      field: '',
      tag: 'DoIDNO',
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      des: '一般用于人员信息的身份证号,支持加密存储'
    }
  },
  {
    // isShow: true,
    type: 'location',
    icon: 'icon-dingwei',
    options: {
      label: '定位',
      model: '',
      width: 24,
      defaultValue: '',
      // isSearch: false,
      otherAddress: '',
      required: false,
      disabled: false,
      defaultAdd: {},
      field: '', //设置默认地址后 可以根据默认地址算出距离 并且填入当前字段
      tag: 'DoLocation',
      help: '',
      des: '获取当前定位'
    }
  },
  {
    type: 'input',
    icon: 'icon-youxiang',
    options: {
      label: '电子邮箱',
      model: '',
      width: 24,
      defaultValue: '',
      isRep: false,
      isSearch: false,
      required: false,
      tag: 'DoEmail',
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      des: '一般用于人员信息的电子邮件,支持在线开启邮件编写'
    }
  },
  {
    type: 'district',
    icon: 'icon-hangzhengquhuaguanli',
    options: {
      label: '行政区划',
      model: '',
      cityStep: 3,
      width: 24,
      defaultValue: [],
      isSearch: false,
      required: false,
      tag: 'DoDistrict',
      pattern: '',
      placeholder: '',
      disabled: false,
      help: '',
      relSelect: [], //联动组件
      des: '一般用于人员信息的所在区域选择, 支持手机端定位显示'
    }
  }
]

export const showComponents = [
  {
    isShow: true,
    type: 'carousel',
    icon: 'icon-lunbo',
    options: {
      label: '轮播图片',
      model: '',
      width: 24,
      images: [],
      fileList: [],
      autoplay: false, //自动播放
      tag: 'DoCarousel',
      help: '',
      des: '循环展示图片'
    }
  },
  {
    isShow: true,
    type: 'countdown',
    icon: 'icon-shijian',
    options: {
      label: '倒计时',
      model: '',
      width: 24,
      timeNum: 1,
      unit: '天', //单位 例如 天、时、分
      fontSize: 22,
      tag: 'DoCountdown',
      format: 'D 天 H 时 m 分 s 秒',
      help: '',
      des: '一般用于表单录入倒计时'
    }
  },
  {
    type: 'progress',
    icon: 'icon-jindutiao',
    options: {
      label: '进度条',
      width: 24,
      defaultValue: 0,
      tag: 'DoProgress',
      max: 100, //进度条满值状态
      comModel: '', //绑定的组件model
      help: '',
      des: '用于监听某个字段的进度'
    }
  },
  {
    isShow: true,
    type: 'inLink',
    icon: 'icon-riqi',
    options: {
      label: '内链接',
      // width: 24,
      tag: 'DoInLink',
      url: '',
      help: '',
      des: '在表单内部引入其它页面'
    }
  },
  {
    isShow: true,
    type: 'outLink',
    icon: 'icon-icon-',
    options: {
      label: '外链接',
      width: 24,
      tag: 'DoOutLink',
      url: '',
      atarget: '_blank',
      help: '',
      des: '跳转到指定地址'
    }
  },
  {
    isShow: true,
    type: 'audio',
    icon: 'icon-004laba-2',
    options: {
      label: '在线音频',
      tag: 'DoAudio',
      width: 24,
      url: '',
      autoplay: false, //自动播放
      loop: false, //循环播放
      muted: false, //静音
      help: '',
      des: '根据url地址播放音频'
    }
  },
  {
    isShow: true,
    type: 'video',
    icon: 'icon-hanhan-01-01',
    options: {
      label: '在线视频',
      tag: 'DoVideo',
      width: 24,
      url: '',
      autoplay: false, //自动播放
      loop: false, //循环播放
      muted: false, //静音
      help: '',
      des: '根据url地址播放视频'
    }
  },
  {
    isShow: true,
    type: 'title',
    icon: 'icon-title',
    options: {
      label: '标题',
      // width: 24,
      tag: 'DoTitle',
      ori: 'center',
      isDashed: false,
      help: '',
      des: '标题说明'
    }
  },
  {
    isShow: true,
    type: 'panel',
    icon: 'icon-zhediemianban',
    elements: [],
    options: {
      label: '折叠面板',
      tag: 'DoPanel',
      // width: 24,
      field: '',
      showType: '1',
      help: '',
      des: '将字段编组方便统一管理'
    }
  }
]

export const systemComponents = [
  {
    type: 'currentUser',
    icon: 'icon-zhanghaodenglu',
    options: {
      label: '当前账号',
      tag: 'DoCurrentUser',
      // isSearch: false,
      width: 24,
      help: '',
      des: '获取当前登录的账号'
    }
  },
  {
    type: 'currentRole',
    icon: 'icon-zhanghao1',
    options: {
      label: '当前角色',
      tag: 'DoCurrentRole',
      // isSearch: false,
      width: 24,
      help: '',
      des: '获取当前登录账号的角色'
    }
  },
  {
    type: 'currentDateTime',
    icon: 'icon-shijian',
    options: {
      label: '当前时间',
      width: 24,
      tag: 'DoCurrentDateTime',
      format: 'yyyy-MM-DD HH:mm:ss',
      help: '',
      des: '获取当前系统时间'
    }
  },
  {
    type: 'selectGroup',
    icon: 'icon-bumenguanli',
    options: {
      label: '选择部门',
      width: 24,
      isSearch: false,
      disabled: false,
      required: false,
      tag: 'DoSelectGroup',
      help: '',
      des: '选择当前域下的所有部门'
    }
  },
  {
    type: 'currentGroup',
    icon: 'icon-web-icon-',
    options: {
      label: '当前部门',
      width: 24,
      tag: 'DoCurrentGroup',
      isSearch: false,
      help: '',
      des: '获取当前登录用户所属部门'
    }
  },
  {
    type: 'selectUser',
    icon: 'icon-icon_zhanghao',
    options: {
      label: '选择账号',
      width: 24,
      // isSearch: false,
      disabled: false,
      required: false,
      tag: 'DoSelectUser',
      help: '',
      des: '选择当前域下的所有账号'
    }
  }
]

export const config = {
  formID: '',
  title: '未命名表单',
  desc: '',
  layout: 'vertical',
  labelWidth: 4,
  wrapperWidth: 24,
  columns: 24,
  theme: 'default',
  ico: ''
}

export const themes = {
  default: {
    name: '默认',
    backgroundColor: '#e9e9e9',
    imgName: '#303030'
  },
  mountains: {
    name: '群山',
    imgName: 'mountains',
    backgroundColor: '#547488'
  },
  concise: {
    name: '简洁',
    imgName: 'concise',
    backgroundColor: '#858E95'
  },
  gentry: {
    name: '绅士',
    imgName: 'gentry',
    backgroundColor: '#7A0D0D'
  }
}
