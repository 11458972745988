const BASE_URL = process.env.VUE_APP_API_BASE_URL
const FORM_BASE_URL = process.env.VUE_APP_FORM_URL

const NETWORK_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_PORTAL_URL
    : 'portalApi'
module.exports = {
  // 获取门户页面信息
  PORCONFIG: `${NETWORK_URL}/portal`,
  // 门户普通账号登录
  ALOGIN: `${BASE_URL}/user/login`,
  // 门户会员登录
  MLOGIN: 'https://mapi.douyiyun.net/member/login',
  //根据表单id获取当前表单数据 /table/表单uuid?view_id=1057
  FORMDATAS: `${BASE_URL}/table/`,

  // 获取表单组件信息
  GETTABLE: `${NETWORK_URL}/table`,
  // 添加表单数据
  ADDFORM: `${FORM_BASE_URL}/index.php`,
  // 添加表单数据
  SEARCHFORM: `${FORM_BASE_URL}/list.php`,
  GETFORM: `${BASE_URL}/form`,
  
  // 获取表单结构
  VIEWTABLE: `${BASE_URL}/form/form/view_tableList`,

  // 添加表单数据
  FORMDATA: `${BASE_URL}/table/`,

  //查询表单单条数据
  FORMDATABYID: `${BASE_URL}/form/form/table_first`,

  //获取消息
  GETMESSAGE: `${BASE_URL}/message`,

  //任务相关
  TASKLIST: `${BASE_URL}/form/task`,

  //第三方token
  // QNTOKEN: `${BASE_URL}/user/api/upload`,  //经理说不安全，用经理给的地址
  QNTOKEN: `${FORM_BASE_URL}/api.php`,

  // BDOCRTOKEN: `${BASE_URL}/user/api/BaiDu`
  // 获取百度,悦保文字识别token
  // OCRTOKEN: `${BASE_URL}/user/api/ocr`,  //经理说不安全，用经理给的地址
  OCRTOKEN: `${FORM_BASE_URL}/api.php`,

  // 版本
  VERSIONS: `${BASE_URL}/user/version`,
}
