import * as $api from '@/services/api'
import { request, METHOD } from '@/utils/request'
import qs from 'qs'
import axios from 'axios'

/**
 * 
 * @returns 在当前表单下添加数据 formID
 */
export async function addFormDataz(form_uuid, params) {
  let path = $api.FORMDATA + form_uuid
  return request(path, METHOD.POST, params)
}
/**
 * 
 * @returns 获取表单结构
 */
export async function viewTable(params) {
  return request($api.VIEWTABLE, METHOD.GET, params)
}

/**
 *
 * @returns 获取表单列表 uuid
 */
export async function getForm({ company_uuid, uuid }) {
  return request(
    `${$api.GETTABLE}/${company_uuid}/${uuid}/index.json`,
    METHOD.GET
  )
}

/**
 *
 * @returns 获取表单列表 uuid
 */
export async function addFormData({ company_uuid, uuid }, params) {
  const url = `${$api.ADDFORM}?company_uuid=${company_uuid}&uuid=${uuid}`
  return request(url, METHOD.POST, params)
}

/**
 *
 * @returns 搜索数据 uuid
 */
export async function searchData({ company_uuid, uuid, search }, params) {
  const url = `${$api.SEARCHFORM}?company_uuid=${company_uuid}&uuid=${uuid}`
  return request(url, METHOD.GET, params)
}

/**
 * 悦保文件识别
 * @param {url,params} info 
 * @returns 
 */
 export async function ybStrCheck({url, token , imgBase64}) {
  let config = {
      'Content-Type': 'application/x-www-form-urlencoded'
  }
  let data = qs.stringify({
      token,
      imgBase64
  })
  return axios.post(url, data, config)
}

/**
* 百度文件识别
* @param {url,params} info 
* @returns 
*/
export async function strCheck({url,access_token }, data) {
  let config = {
      'Content-Type': 'application/x-www-form-urlencoded'
  }
  return axios({
      url: url+'?access_token='+access_token,
      method: 'post',
      data: qs.stringify(data),
      headers: config
  })
}
