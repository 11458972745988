import * as $api from '@/services/api'
import { request, METHOD } from '@/utils/request'

/**
 *
 * @returns 获取七牛云token
 */
export async function qntoken(params) {
  return request($api.QNTOKEN, METHOD.GET, params)
}
/**
 * 
 * @returns 获取百度,悦保文字识别token
 */
 export async function ocrToken(params) {
  return request($api.OCRTOKEN, METHOD.GET, params)
}
