<template>
    <div class="sign">
        <p class="tip" v-show="!value.url || !resultImg">tip：签名结束后需要点击确定按钮</p>
        <div class="showImg" style="height: 100%" v-if="value.url || resultImg">
            <img width="100%" height="100%" :src="resultImg || value.url" alt="图片地址错误"/>
        </div>
        <div class="container" v-else>
            <div class="disabled" v-if="disabled"></div>
            <vue-esign 
            ref="esign"
            v-else
            :height="185"
            :isCrop="isCrop"
            :lineWidth="lineWidth"
            :lineColor="lineColor"
            :bgColor.sync="bgColor" 
            @drawEnd="drawEnd"
            />
            <div class="btns">
                <a-button size="small" @click="handleReset">清空画板</a-button>
                <a-button size="small" type="primary" @click="handleGenerate">确认</a-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'jSign',
        props: ['value', 'disabled'],
        data() {
            return {
                lineWidth: 3,
                lineColor: '#000000',
                bgColor: '',
                resultImg: '',
                isCrop: false
            }
        },
        created() {
            // console.log(this.value)
        },
        methods:{
            handleReset () {
                this.$refs.esign.reset()
            },
            handleGenerate () {
                this.$confirm({
                    title: '确认后不可更改!',
                    okText: '确定',
                    cancelText: '取消',
                    onOk: () => {
                        this.$refs.esign.generate().then(res => {
                            this.resultImg = res
                            const blob = this.base64ToBlob(res);
                            const file = this.blobToFile(blob, '签名.jpg');
                            this.$emit('input', [file])
                        }).catch(() => {
                            // alert(err) // 画布没有签字时会执行这里 'Not Signned'
                            this.$message.warning('签名画布为空！')
                        })
                    },
                    class: 'test',
                });
            },
            drawEnd(){
                console.log("结束绘制")
            },
            base64ToBlob(dataurl) { 
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            },
            //将blob转换为file
            blobToFile: function(theBlob, fileName){
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                return theBlob;
            }
        }
    }
</script>

<style lang="less" scoped>
canvas{
    width: 100% !important;
}
p{
    margin: 0;
}
.sign {
    width: 100%;
    height: 210px;
    .tip {
        color: rgb(162, 162, 162);
        height: 30px;
        line-height: 30px;
    }
    .container {
        position: relative;
        width: 100%;
        border: double 3px transparent;
        border-radius: 5px;
        background-image: linear-gradient(white, white),
            radial-gradient(circle at top left, #4bc5e8, #9f6274);
        background-origin: border-box;
        background-clip: content-box, border-box;
        height: 184px;
        .disabled {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(146, 146, 146, .2);
            z-index: 99999;
        }
        .btns {
            position: absolute;
            right: 0;
            top: 0;
            padding: 5px 5px 0 0;
            button {
                margin-left: 10px;
            }
        }
    }
}

</style>
