<template>
  <div class="city">
    <a-cascader
      v-model="result"
      :options="cityDatas"
      :placeholder="placeholder"
      :disabled="disabled"
      style="width: 100%"
      change-on-select
      @change="$emit('change', result.join(','))"
    />
  </div>
</template>

<script>
import citys from '@/configJson/citys'
export default {
  name: 'jCity',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    step: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      cityDatas: [],
      result: []
    }
  },
  created() {
    this.result = this.value
    if (this.step == 0 || this.setp >= 3) {
      this.cityDatas = citys
    } else {
      this.cityDatas = this.filterCity(citys, this.step)
    }
  },
  methods: {
    filterCity(citys, step, index = 1) {
      let copyCitys = []
      citys.forEach((city) => {
        let tempCity = {
          label: city.label,
          value: city.value
        }
        if (Array.isArray(city.children) && index <= step - 1) {
          tempCity.children = this.filterCity(city.children, step, 2)
        }
        copyCitys.push(tempCity)
      })
      return copyCitys
    }
  },
  watch: {
    result(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.result = newVal
    }
  }
}
</script>

<style lang="less" scoped>
.city {
  width: 100%;
}
</style>
