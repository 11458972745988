<template>
  <div class="j-text">
    <nobr>
      <slot></slot>
    </nobr>
  </div>
</template>
<script>
export default {
  name: 'jText'
}
</script>
<style lang="less" scoped>
.j-text {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  nobr {
    text-overflow: ellipsis;
  }
}
</style>
