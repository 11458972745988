<template>
  <div style="width: 100%">
    <a-form-model-item
      :class="{ brError: error, thin: disabled }"
      v-if="element.options"
      :prop="element.options.model"
      :label="element.options.label"
    >
      <pre class="help" v-if="element.options.help && !field">{{
        element.options.help
      }}</pre>
      <template v-if="element.type == 'input'">
        <template
          v-if="
            element.options.tag == 'DoNumber' ||
            element.options.tag == 'DoFloat'
          "
        >
          <a-input
            :disabled="disabled"
            v-model="dataModel"
            :placeholder="element.options.placeholder"
            @change="em_Progress(dataModel, element.options)"
            @blur="changeNum(dataModel)"
            @focus="focusNum(dataModel)"
            :suffix="element.options.unit"
          />
        </template>
        <template v-else-if="element.options.tag == 'DoIDNO'">
          <idNo v-model="dataModel" :options="element.options"></idNo>
        </template>
        <template v-else>
          <a-input
            :disabled="disabled"
            v-model="dataModel"
            :placeholder="element.options.placeholder"
          />
        </template>
      </template>
      <template v-if="element.type == 'textarea'">
        <j-textarea :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'checkbox'">
        <a-checkbox-group v-model="dataModel" :disabled="disabled">
          <a-checkbox
            v-for="(item, index) in element.options.options"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-checkbox
          >
        </a-checkbox-group>
      </template>
      <template v-if="element.type == 'radio'">
        <a-radio-group v-model="dataModel" :disabled="disabled">
          <a-radio
            v-for="(item, index) in element.options.options"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-radio
          >
        </a-radio-group>
      </template>
      <template v-if="element.type == 'switch'">
        <a-switch v-model="dataModel" :disabled="disabled" />
      </template>
      <template v-if="element.type == 'rate'">
        <a-rate
          :count="element.options.count"
          v-model="dataModel"
          :disabled="disabled"
        />
      </template>
      <template v-if="element.type == 'slider'">
        <a-slider
          :max="element.options.max"
          :min="element.options.min"
          :step="element.options.step"
          v-model="dataModel"
          :disabled="disabled"
        />
      </template>
      <template v-if="element.type == 'selectGroup'">
        <j-select-group v-model="dataModel" :options="element.options" />
      </template>
      <template v-if="element.type == 'selectUser'">
        <j-select-person :options="element.options" v-model="dataModel" />
        <!-- <j-select-user v-model="dataModel" :options="element.options"/> -->
      </template>
      <template v-if="element.type == 'currentUser'">
        <j-current-user v-model="dataModel" />
      </template>
      <template v-if="element.type == 'select'">
        <a-select
          :dropdownMatchSelectWidth="false"
          v-model="dataModel"
          :disabled="disabled"
          :allowClear="true"
          @change="exeRel"
        >
          <a-select-option
            v-for="(item, index) in element.options.options"
            :key="index"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </template>
      <template v-if="element.type == 'date'">
        <j-date
          :disabled="disabled"
          :options="element.options"
          v-model="dataModel"
        />
      </template>
      <template v-if="element.type == 'superDate'">
        <a-range-picker
          style="width: 100%"
          valueFormat="YYYY-MM-DD"
          v-model="dataModel"
          @change="datachange"
        />
      </template>
      <template v-if="element.type == 'district'">
        <j-city
          v-model="dataModel"
          :placeholder="element.options.placeholder"
          :disabled="disabled"
          :step="element.options.cityStep"
          @change="exeRel"
        ></j-city>
      </template>
      <template v-if="element.type == 'code'">
        <j-code
          :options="element.options"
          v-model="dataModel"
          :placeholder="element.options.placeholder"
        />
      </template>
      <template v-if="element.type == 'selectData'">
        <j-select-data
          v-model="dataModel"
          :options="element.options"
        ></j-select-data>
      </template>
      <template v-if="element.type == 'log'">
        <j-log v-model="dataModel" :options="element.options" />
      </template>
      <template v-if="element.type == 'file' || element.type == 'image'">
        <fileUpload :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'sign'">
          <sign v-model="dataModel" :disabled="disabled"/>
      </template>
      <template v-if="element.type == 'location'">
        <j-location :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'task'">
        <j-task :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'currentRole'">
        <j-current-role :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'currentDateTime'">
        <j-current-date-time
          :format="element.options.format"
          v-model="dataModel"
        />
      </template>
      <template v-if="element.type == 'currentGroup'">
        <j-current-group v-model="dataModel" />
      </template>
      <template v-if="element.type == 'table'">
        <j-sublist
          :isShow="true"
          v-model="dataModel"
          :options="element.options"
          :tables="element.options.tables"
        ></j-sublist>
      </template>
      <template v-if="element.type == 'progress'">
        <j-progress :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'collect'">
        <j-collect :options="element.options" v-model="dataModel"></j-collect>
      </template>
      <template v-if="element.type == 'wordTemplate'">
        <j-word-template :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'flows'">
        <j-flows :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'alert'">
        <j-warn :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'audio'">
        <j-audio :options="element.options" v-model="dataModel" />
      </template>
      <template v-if="element.type == 'video'">
        <j-video :options="element.options" v-model="dataModel" />
      </template>
      <span class="error" v-if="error">
        {{ error }}
      </span>
    </a-form-model-item>
  </div>
</template>

<script>
import fileUpload from '@/pages/form/components/upload/file/FileUpload.vue'
import sign from '@/pages/form/components/signature/Signature.vue'
import idNo from '@/pages/form/components/idNo/idNo.vue'
import { smalltoBIG, formatDate } from '@/utils/jutil'
// import { getEl } from '@/services/public.js'
import moment from 'moment'
// import { valueCheck } from '@/services/myForm'
import { mapMutations } from 'vuex'
export default {
  components: {fileUpload, sign, idNo},
  props: ['el', 'models', 'field', 'uuid', 'value', 'formID', 'superDate', 'getFindDatas', 'tabChangeValue'],
  data() {
    return {
      element: {
        options: {}
      },
      dataModel: '',
      disabled: false,
      error: ''
    }
  },
  created() {
    if (Array.isArray(this.getFindDatas)) {  //进入此if，表示此组件被showDatas组件调用
      this.models = this.getFindDatas[this.tabChangeValue]  //用查询到的数据进行数据回填
    }
    
    this.init()
    if (this.element.type == 'date') {
      this.dateChange(this.element.options.defaultValue, this.element.options)
    }
  },
  methods: {
    ...mapMutations('self', ['setRepCheckRes']),
    datachange(val) {
      console.log(val)
    },
    init() {
      if (!this.field && !this.uuid) {
        this.element = this.el
        this.disabled = this.element.options.disabled
        this.dataModel = this.models[this.el.options.model]
        if (!this.dataModel) {
          if (this.el.type == 'checkbox') {
            this.dataModel = []
          }
          if (this.el.type == 'switch') {
            this.dataModel = false
          }
        }
      } else {
        this.getElement()
      }
    },
    getElement() {
      // getEl({ fd_name: this.field, uuid: this.uuid }).then((res) => {
      //   let element = res.data.data[0]
      //   if (res.data.code == 11) {
      //     return
      //   }
      //   this.element = element
      //   if (this.superDate && this.element.type == 'date') {
      //     this.element.type = 'superDate'
      //   }
      //   this.disabled = false
      //   this.dataModel = this.value
      // })
    },
    dateChange(val, options) {
      let date = ''
      if (typeof val == 'string') {
        date = val
      } else {
        date = val.format(options.format)
      }
      let obj = {}
      obj[options.model] = date
      this.$bus.$emit('em_dateChange', obj)
    },
    em_Progress(val, optins) {
      let { model, field } = optins
      this.$bus.$emit('em_progress', {
        model,
        val
      })
      if (field) {
        this.$bus.$emit('em_checkContent', [
          {
            model: field,
            context: smalltoBIG(val)
          }
        ])
      }
    },
    //检查内容重复
    getElByField(element, val, id) {
      let uuid =
        this.formID || this.$route.params.uuid || this.$route.query.formId
      if (element.options.isRep) {
        let params = {
          uuid,
          id,
          data: {}
        }
        params.data[element.options.model] = val
        // valueCheck(params).then((res) => {
        //   this.$forceUpdate()
        //   if (res.data.data) {
        //     this.error = '内容重复'
        //     this.setRepCheckRes(false)
        //   } else {
        //     this.error = ''
        //     this.setRepCheckRes(true)
        //   }
        // })
      }
    },
    changeNum(val) {
      let { tag, model, isNum } = this.element.options
      if (tag == 'DoFloat') {
        let num = Number(val)
        if (num && isNum && isNum > 0) {
          this.$bus.$emit('em_checkContent', [
            {
              model,
              context: num.toFixed(isNum)
            }
          ])
        }
      } else {
        if (!val) {
          this.dataModel = 0
        }
      }
    },
    focusNum(val) {
      if (val == 0) {
        this.dataModel = ''
      }
    },
    // 执行联动
    exeRel(value) {
      this.el.options.relSelect?.forEach((res) => {
        this.$bus.$emit(this.getEmitName(this.el.options, res), {
          model: this.el.options.model,
          value
        })
      })
    },
    getEmitName(options, basicName) {
      if (options.isTable && options.rowId) {
        return 'table' + options.rowId + basicName
      } else {
        return basicName
      }
    }
  },
  watch: {
    dataModel(val) {
      let temp = {}
      temp[this.element.options.model] = val
      this.$emit('dataUpd', temp)

      if (this.element.type == 'time' || this.element.type == 'date') {
        let newdate = formatDate(val)
        this.$emit('input', moment(newdate).format(this.element.options.format))
      } else {
        this.$emit('input', val)
      }
      if (this.el?.options.isRep && this.dataModel) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.getElByField(this.element, val, this.models.id)
        }, 500)
      }
      if (this.element.type == 'select' || this.element.type == 'district') {
        if (!val) {
          return
        }
        let value = val
        if (this.element.type == 'district') {
          value = value.join(',')
        }
        this.exeRel(value)
      }
    },
    field(val) {
      this.getElement()
    },
    models: {
      deep: true,
      handler() {
        this.init()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0px;
  padding: 5px 0;
  position: relative;
  &.brError * {
    border-color: red;
    color: red;
  }
  .error {
    position: absolute;
    right: 0;
    bottom: 15px;
    color: red;
    display: inline;
  }
  /deep/ .ant-input-disabled,
  .ant-select-disabled {
    color: rgba(0, 0, 0, 1);
  }
}
</style>
