<template>
    <div class="video">
        <div class="upload-area" v-if="!isShow">
            <a-input v-if="options.isText" v-model="urlText" placeholder="请填写视频地址"></a-input>
            <Upload v-else text="视频" type="video" v-model="file"/>
        </div>
        <div class="show-area" v-else>
            <video 
            v-if="url"
            :src="url"
            controls="controls" 
            :autoplay="options.autoplay" 
            :loop="options.loop"
            :muted="options.muted">
                您的浏览器不支持 视频 展示。
            </video>
            <span v-else>未上传视频</span>
        </div>
    </div>
</template>

<script>
import Upload from '../Upload.vue'
export default {
    name: 'jVideo',
    components: {
        Upload
    },
    props: ['options', 'isShow', 'value', 'url'],
    data(){
        return {
          tableDisabled: '',//值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
          file: [],
          urlText: ''
        }
    },
    methods: {
    },
    created(){
        //值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
        this.tableDisabled = localStorage.getItem('zSee')
        if(this.options.isText) {
            this.urlText = Array.isArray(this.value) ? '' : this.value
        } else {
            if(Array.isArray(this.value)){
                this.file = this.value
            }
        }
    },
    watch: {
        file: {
            deep: true,
            handler(val){
                this.$emit('input', val)
            }
        },
        urlText(val){
            this.$emit('input', val)
        }
    }
}
</script>

<style lang="less" scoped>
    .video {
        video {
            width: 100%;
            max-width: 300px;
            max-height: 250px;
        }
    }
</style>