const RegEx = {
  //身份证
  DoIDNO:
    /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
  //手机号
  DoPhone:
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
  //姓名
  DoName: /^[a-zA-Z\u4E00-\u9FA5]{2,20}$/,
  //邮箱
  DoEmail: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
  //验证所有整数，包括0和正负数整数
  DoNumber: /^(0|[1-9][0-9]*|-[1-9][0-9]*)$/,
  //验证非0开头的无限位整数和小数。整数支持无限位，小数点前支持无限位，小数点后最多保留10位。
  DoFloat:
    /^(([^0][0-9]+|0)\.([0-9]{1,10})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,10})$)|^(([1-9]+)$)/,
  //车牌号
  CarNumber:
    /[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领]{1}[A-Z]{1}[A-Z0-9]{5,6}/
}
export default RegEx
