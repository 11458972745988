<template>
  <div
    class="theme"
    v-if="config"
    :style="{
      'background-color': activeTheme.backgroundColor,
      height: '100%'
    }"
  >
    <div class="theme-box">
      <div class="theme-box_header">
        <div
          class="header-default"
          v-if="config.theme === 'default'"
          :style="{ 'background-color': activeTheme.imgName }"
        ></div>
        <div
          class="header-theme"
          v-else
          :style="{
            'background-image':
              'url(' +
              (config.theme == 'custom'
                ? activeTheme.imgUrl
                : require('@/assets/img/formTheme/' +
                    activeTheme.imgName +
                    '.png')) +
              ')'
          }"
        ></div>
        <div class="header-text">
          <template>
            <h2 class="text-title">{{ config.title }}</h2>
            <pre class="text-desc" v-if="config.desc">{{ config.desc }}</pre>
          </template>
        </div>
      </div>
      <div class="theme-box_forms" style="display: flex; flex-direction: column;">

        <!-- a-tabs组件，只在展示查询结果时显示 -->
        <a-tabs default-active-key="0" @change="tabChange" v-if="Array.isArray(getFindDatas) && getFindDatas.length == 1">
          <a-tab-pane key="0" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
        </a-tabs>
        <a-tabs default-active-key="0" @change="tabChange" v-if="Array.isArray(getFindDatas) && getFindDatas.length == 2">
          <a-tab-pane key="0" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="1" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
        </a-tabs>
        <a-tabs default-active-key="0" @change="tabChange" v-if="Array.isArray(getFindDatas) && getFindDatas.length == 3">
          <a-tab-pane key="0" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="1" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="2" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
        </a-tabs>
        <a-tabs default-active-key="0" @change="tabChange" v-if="Array.isArray(getFindDatas) && getFindDatas.length == 4">
          <a-tab-pane key="0" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="1" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="2" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="3" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
        </a-tabs>
        <a-tabs default-active-key="0" @change="tabChange" v-if="Array.isArray(getFindDatas) && getFindDatas.length == 5">
          <a-tab-pane key="0" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="1" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="2" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="3" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="4" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
        </a-tabs>
        <a-tabs default-active-key="0" @change="tabChange" v-if="Array.isArray(getFindDatas) && getFindDatas.length == 6">
          <a-tab-pane key="0" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="1" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="2" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="3" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="4" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="5" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
        </a-tabs>
        <a-tabs default-active-key="0" @change="tabChange" v-if="Array.isArray(getFindDatas) && getFindDatas.length >= 7">
          <a-tab-pane key="0" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="1" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="2" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="3" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="4" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="5" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
          <a-tab-pane key="6" tab="●">
            <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue"> </look-form>
          </a-tab-pane>
        </a-tabs>

        <!-- 只要不是展示查询结果的页面都要显示 -->
        <look-form ref="isLookForm" :form="form" :getFindDatas="getFindDatas" :tabChangeValue="tabChangeValue" v-if="!Array.isArray(getFindDatas)"> </look-form>
      </div>
      <!-- fromShowDatas值为1，表示theme组件的父组件是showDatas组件 -->
      <div class="theme-box_submit" v-if="fromShowDatas === '1'">
        <a-button type="primary" @click="getData" v-if="getFindDatas[tabChangeValue].publish === '0'">{{ distinguish==='1'?'查询': '保存' }}</a-button>
      </div>
      <div class="theme-box_submit" v-else>
        <a-button type="primary" @click="getData">{{ distinguish==='1'?'查询': '保存' }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from '@/utils/upload'
import { themes } from '@/configJson/componentsConfig'
import LookForm from './LookForm.vue'
export default {
  components: { LookForm },
  props: {
    getFindDatas: {
      type: Array
    },
    config: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    distinguish: {
      type: String
    },
    fromShowDatas: {
      type: String
    }
  },
  data() {
    return {
      themes,
      tabChangeValue: '0'  //存储tab页的change值
    }
  },
  computed: {
    activeTheme() {
      if (themes[this.config.theme]) {
        return themes[this.config.theme]
      } else {
        return {
          imgUrl: this.config.imgUrl,
          backgroundColor: this.config.backgroundColor
        }
      }
    }
  },
  created() {
    console.log('getFindDatas', this.getFindDatas)
    console.log('tabChangeValue', this.tabChangeValue)
  },
  methods: {
    // 标签页的change事件
    tabChange(val) {
      this.tabChangeValue = val
    },

    async getData() {
      let data = await this.$refs.isLookForm.getData()

      if (Array.isArray(this.getFindDatas)) {  //进入此if表示保存的是查询的数据，需要加上id
        data.id = this.getFindDatas[this.tabChangeValue].id
      }

      if (!data) {
        return
      }
      let files = []
      let rel = {}
      let i = 0
      for (let key in data) {
        if (data[key] && Array.isArray(data[key]) && data[key][0]) {
          let arrIndex = []
          data[key].forEach((res) => {
            if (res.constructor == File || res.constructor == Blob) {
              files.push(res)
              arrIndex.push(i++)
            } else {
              arrIndex.push(-1)
            }
          })
          rel[key] = arrIndex
        }
      }
      if (files.length > 0) {
        this.uploadFile(files, data, rel)
      } else {
        this.$emit('submit', data)
      }
    },
    uploadFile(fileList, formData, rel) {
      const hide = this.$message.loading('文件上传中...', 0)
      let uploads = []
      fileList.forEach((file) => {
        uploads.push(upload(file))
      })
      let fileDatas = []
      console.log('uploads', uploads)
      Promise.all(uploads)
        .then((values) => {
          hide()
          values.forEach((data) => {
            fileDatas.push({
              uid: data.file.uid,
              name: data.file.name,
              status: 'done',
              url: data.key,
              size: data.file.size
            })
          })
          for (let key in rel) {
            rel[key].forEach((r, index) => {
              if (r != -1) {
                formData[key][index] = fileDatas[r]
              }
            })
          }
          this.$emit('submit', formData)
        })
        .catch(() => {
          hide()
          this.$message.error('上传失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import './css/theme.less';
</style>
