import Cookie from 'js-cookie'

// 响应错误统一处理
const error = {
  onFulfilled(response) {
    return response
  },
  onRejected(error, options) {
    const { message } = options
    const { response } = error
    let { msg } = response.data
    if (msg) {
      message.error(msg)
    }
    return Promise.reject(error)
  }
}
const reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {message}
   * @returns {*}
   */
  onFulfilled(config) {
    if (Cookie.get('AUTHORIZATION') != null) {
      config.headers.AUTHORIZATION = Cookie.get('AUTHORIZATION')
    }
    return config
  },
  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected(error, options) {
    const { message } = options
    message.error(error.message)
    return Promise.reject(error)
  }
}

export default {
  request: [reqCommon], // 请求拦截
  response: [error] // 响应拦截
}
