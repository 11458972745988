<template>
    <div>
        <!-- 视频 -->
        <div v-if="type === 'video'">
            <a-upload
            :multiple="true"
            :file-list="fileList" 
            :remove="handleRemove" 
            :before-upload="beforeUpload"
            accept='video/*'
            capture="camera"
            class="upload-list-inline">
                <div class="uploadBtn">
                    <a-button type="link" :disabled="disabled" style="padding-left: 0px"> 选择视频文件</a-button>
                    <img src="@/assets/video.png" title="录制视频" width="66px"/>
                </div>
            </a-upload>
        </div>
        <!-- 音频 -->
        <div v-if="type === 'audio'">
            <a-upload
            :multiple="true"
            :file-list="fileList" 
            :remove="handleRemove" 
            :before-upload="beforeUpload"
            accept='audio/*'
            class="upload-list-inline">
                <div class="uploadBtn">
                    <a-button type="link" :disabled="disabled" style="padding-left: 0px"> 选择音频文件</a-button>
                    <img src="@/assets/audio.png" title="录音" width="66px"/>
                </div>
            </a-upload>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        'disabled': Boolean,
        'value': {},
        'text': {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            fileList: [],
            tempFiles: [],
        }
    },
    created() {
        if(Array.isArray(this.value)){
            this.fileList = this.value
        }
    },
    methods: {
        beforeUpload(file){
            if(this.check(file)){
                this.$set(this.fileList, 0, file)
                this.$emit('input', this.fileList)
            }
            return false
        },
        check(file){
            if(file?.type?.indexOf(this.type) == -1){
                this.$message.error('请上传' + this.text + '文件')
                return false
            }
            return true
        },
        handleRemove(){
            this.fileList.splice(0, 1)
            this.$emit('input', [])
        }
    }
}
</script>
<style>
.uploadBtn {
    display: flex;
    flex-direction: column;
}
</style>