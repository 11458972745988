<template>
  <div class="strCheck">
    <a-upload
      :before-upload="beforeUpload"
      :disabled="loading || options.disabled"
    >
      <a-button
        type="link"
        style="padding: 0; padding-right: 5px"
        :disabled="loading || options.disabled"
        >图像识别</a-button
      >
      <a-icon type="loading" v-if="loading" />
    </a-upload>
  </div>
</template>

<script>
import { JSONPath } from 'jsonpath-plus'
import { strCheck, ybStrCheck } from '@/services/form'
import { versions } from '@/services/version'
import { ocrToken } from '@/services/tokens'
import { mapState } from 'vuex'
import { formatDate } from '@/utils/jutil'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'jStrCheck',
  props: ['options'],
  computed: {
    ...mapState('self', ['elements']),
  },
  data() {
    return {
      loading: false,
      bdBaseUrl: '/baiduApi/rest/2.0/ocr/v1/',
      ocrUrl: '',
      data: {
        image: '',
        id_card_side: 'front',
      },
      bd_token: '',
      yb_token: '',
      currentType: {},
      fun: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      console.log('this.options', this.options)
      this.currentType = this.options.type
      // console.log('this.currentType', this.currentType);
      if (this.currentType.type === '百度AI') {
        this.ocrUrl = this.bdBaseUrl + this.currentType.url
      } else if (this.currentType.type === '悦保科技') {
        this.ocrUrl = this.currentType.url
      }
      console.log(this.ocrUrl)
    },
    beforeUpload(file) {
      this.loading = true
      localStorage.removeItem('ybocr')
      localStorage.removeItem('bdocr')
      getBase64(file).then((base64) => {
        this.data.image = base64
        let token = localStorage.getItem('bdocr')
        let yb_token = localStorage.getItem('ybocr')
        if (token && yb_token) {
          this.bd_token = token
          this.yb_token = yb_token
          this.isCheck()
        } else {
          let company_uuid = localStorage.getItem('company_uuid')
          ocrToken({
            act: 'ocr',
            uuid: company_uuid
          })
            .then((res) => {
              console.log('ocr需要的token', res)
              let { baidu: bd_token, YueBao: yb_token } = res.data
              this.bd_token = bd_token
              this.yb_token = yb_token
              console.log(bd_token, yb_token)
              localStorage.setItem('ybocr', yb_token)
              localStorage.setItem('bdocr', bd_token)
              this.isCheck()
            })
            .catch(() => {
              this.$message.error('系统内部错误！')
              this.loading = false
            })
        }
      })
      return false
    },
    isCheck() {
      // 如果识别行驶证，ocr_id为6
      if (this.currentType.type === '百度AI'&&this.currentType.id == 6) {
        console.log('行驶证识别')
        this.strCheck()
        // versions({ format: 1, ocr_id: 6 })    //ocr_id: 6, 1, 4
        // .then((res) => {
        //   let { code, msg } = res.data.data
        //   if (code == 0) {
        //     this.strCheck()
        //   } else {
        //     this.$message.error(msg)
        //     this.loading = false
        //   }
        // })
        // .catch(() => {
        //   this.$message.error('系统内部错误！')
        // })
      }
      // 如果识别驾驶证，ocr_id为1
      if (this.currentType.type === '百度AI'&&this.currentType.id == 1) {
        console.log('驾驶证识别')
        this.strCheck()
        // versions({ format: 1, ocr_id: 1 })    //ocr_id: 6, 1, 4
        // .then((res) => {
        //   let { code, msg } = res.data.data
        //   if (code == 0) {
        //     this.strCheck()
        //   } else {
        //     this.$message.error(msg)
        //     this.loading = false
        //   }
        // })
        // .catch(() => {
        //   this.$message.error('系统内部错误！')
        // })
      }
      // 如果识别医疗识别，ocr_id为4
      if (this.currentType.type === '悦保科技') {
        console.log('医疗识别')
        this.strCheck()
        // versions({ format: 1, ocr_id: 4 })    //ocr_id: 6, 1, 4
        // .then((res) => {
        //   let { code, msg } = res.data.data
        //   if (code == 0) {
        //     this.strCheck()
        //   } else {
        //     this.$message.error(msg)
        //     this.loading = false
        //   }
        // })
        // .catch(() => {
        //   this.$message.error('系统内部错误！')
        // })
        // return
      }
    },
    strCheck() {
      // console.log('包邮',this.currentType)
      if (this.currentType.type === '百度AI') {
        this.bdOcr()
      } else if (this.currentType.type === '悦保科技') {
        this.ybOcr()
      }
    },
    ybOcr() {
      let params = {
        url: this.ocrUrl,
        token: this.yb_token,
        imgBase64: this.data.image,
      }
      ybStrCheck(params)
        .then((res) => {
          const { status, data, message } = res.data
          if (status === 200) {
            this.setResusltToForm(data)
            this.$message.success('识别成功！')
          } else {
            this.$message.error(message)
          }
          this.loading = false
        })
        .catch(() => {
          this.$message.error('使用次数已用完，请及时购买！')
          this.loading = false
        })
    },
    bdOcr() {
      strCheck(
        {
          url: this.ocrUrl,
          access_token: this.bd_token,
        },
        this.data
      )
        .then((res) => {
          let { words_result, error_msg, error_code } = res.data
          if (words_result) {
            this.setResusltToForm(words_result)
          }
          if (error_code == 282103) {
            this.$message.error('识别错误，模板匹配失败！', 5)
          } else if (error_code) {
            this.$message.error(error_msg, 5)
          } else {
            this.$message.success('识别成功！')
          }
          this.loading = false
        })
        .catch((e) => {
          this.$message.error('系统内部错误！')
          this.loading = false
          console.log(e)
        })
    },
    setResusltToForm(data) {
      let result = []
      this.options.fieldMaps?.forEach((map) => {
        const { model } = map
        let context = this.oJsonPath(data, map)
        if (model) {
          result.push({
            model,
            context,
          })
        }
      })
      if (result.length === 0) return
      // 如果当前关联下拉框属于子表中 那么他只能关联取值当前表内的字段
      if (this.options.isTable) {
        let obj = {}
        result.forEach((res) => {
          obj[res.model] = res.context
        })
        this.$bus.$emit(this.options.isTable + 'em_relValue', {
          index: this.options.index,
          datas: obj,
        })
      } else {
        this.$bus.$emit('em_checkContent', result)
      }
    },
    oJsonPath(json, { jsonPath: path, type: data_type, isDateFormat }) {
      let res = JSONPath({
        path,
        json,
      })
      if (!data_type || data_type === 'string') {
        let value = res[0]
        if (isDateFormat) {
          return formatDate(value)
        }
        return value
      } else if (data_type === 'array') {
        return res.join('，')
      } else {
        return res[0]
      }
    },
  },
  watch: {
    elements: {
      deep: true,
      handler() {
        let textareaList = this.elements.filter((el) => {
          return el.type == 'textarea'
        })
        let is = textareaList.find((res) => {
          return res.options.model == this.options.comModel
        })
        if (!is) {
          this.options.comModel = ''
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-upload-list {
  display: none;
}
.strCheck {
  padding: 13px 0;
  .null {
    color: #999;
    font-size: 12px;
  }
}
</style>
