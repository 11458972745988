import * as $api from '@/services/api'
import { request, METHOD } from '@/utils/request'
import { Base64 } from 'js-base64'
/**
 *
 * @returns 获取门户配置信息
 */
// export async function porConfig(params) {
//   return request($api.PORCONFIG, METHOD.GET, params)
// }

/**
 *
 * @returns 获取门户配置信息
 */
export async function porConfig(uuid) {
  return request(`${$api.PORCONFIG}/${uuid}/index.json`, 
  METHOD.GET,
  { uuid },
  {
    headers: {
      'Cache-control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': 0
    }
  })
}

/**
 * 门户登录
 * @param name 账户名
 * @param password 账户密码
 */
export async function login({ name, password }, loginType = 0, { domain }) {
  let base64Str = Base64.encode(name + ':' + password)
  return request(
    loginType == 0 ? $api.ALOGIN : $api.MLOGIN,
    METHOD.GET,
    { domain },
    {
      headers: {
        authorization: 'Basic ' + base64Str
      }
    }
  )
}

/**
 *
 * 根据表单id获取当前表单数据 /table/form_uuid?view_id=1057
 */
export async function viewAndForm(form_uuid, params) {
  let path = $api.FORMDATAS + form_uuid
  return request(path, METHOD.GET, params)
}

/**
 * @returns 获取表单列表
 */
export async function getForm(params) {
  return request($api.GETFORM, METHOD.GET, params)
}

/**
 * 根据表单id及数据id 获取单条数据
 */
export async function formData(params) {
  return request($api.FORMDATABYID, METHOD.POST, params)
}

/**
 * 获取消息
 */
export async function getMessageList(params) {
  // params = delUndefined(params)
  return request($api.GETMESSAGE, METHOD.GET, params, {})
}

/**
 * @returns 获取任务列表
 */
export async function taskList(params) {
  return request($api.TASKLIST, METHOD.GET, params)
}
