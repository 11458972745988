<template>
  <div class="code">
    <a-input :disabled="true" placeholder="由系统自动生成" :value="codeVal" />
  </div>
</template>

<script>
export default {
  name: 'jCode',
  props: ['options', 'value'],
  data() {
    return {
      codeVal: ''
    }
  },
  created() {
    if (this.value) {
      this.codeVal = this.value
    }
  },
  methods: {
    initCode() {
      let randomCode = this.nanoid() + this.nanoid()
      let newSr =
        this.options.prefix + randomCode.substring(0, this.options.length)
      this.$emit('input', newSr)
    }
  }
}
</script>

<style></style>
