<template>
  <div class="lineTitle">{{ options.label }}</div>
</template>
<script>
export default {
  name: 'jLineTitle',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="less" scoped>
.lineTitle {
  border-left: 3px solid #ccc;
  padding-left: 10px;
}
</style>
