<template>
  <a-avatar
    class="side-self_avatar"
    :size="size"
    style="backgroundcolor: #a857ed; font-size: 14px"
    :src="imgUrl"
  >
    {{ name.substring(name.length - 2) }}
  </a-avatar>
</template>
<script>
export default {
  name: 'jAvatar',
  props: {
    name: {
      type: String,
      default: '--'
    },
    size: {
      default: 'default'
    },
    url: {
      default: ''
    }
  },
  data() {
    return {
      imgUrl: ''
    }
  },
  created() {
    this.imgUrl = this.url ? process.env.VUE_APP_STATIC_URL + this.url : ''
  }
}
</script>
