<template>
  <div class="plac">
    <div class="ql-container ql-snow" style="border: none">
      <div class="ql-editor" v-html="options.content"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'jPlac',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="less" scoped>
.plac {
  padding: 5px 0;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
