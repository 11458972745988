<template>
    <div class="idNo">
        <a-input v-model="context" @change="upd" :disabled="options.disabled || tableDisabled == 1" :placeholder="options.placeholder"/>
    </div>
</template>

<script>
    import RegEx from '@/configJson/regEx'
    export default {
        name: 'jIdNo',
        props:['value', 'options'],
        data() {
            return {
                tableDisabled: '',    //值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
                context: ''
            }
        },
        created() {
            this.context = this.value
            //值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
            this.tableDisabled = localStorage.getItem('zSee')
        },
        methods: { 
            upd(){
                var re = new RegExp(RegEx.DoIDNO);
                if(re.test(this.context)){
                    let bir = this.getBirthdayFromIdCard(this.context)
                    this.$bus.$emit('em_checkContent',[{
                        model:this.options.field,
                        context: bir
                    }])
                }
                this.$emit('input', this.context)
            },
            getBirthdayFromIdCard(idCard){
                var birthday = "";
                if(idCard != null && idCard != ""){
                    if(idCard.length == 15){
                        birthday = "19"+idCard.substr(6,6);
                    } else if(idCard.length == 18){
                        birthday = idCard.substr(6,8);
                    }
                
                    birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-");
                }
                return birthday;
            }
        },
        watch: {
            value(){
                this.context = this.value
            }
        }
    }
</script>

<style>

</style>