<template>
  <div class="countdown">
    <a-statistic-countdown
      :title="options.label"
      :value="deadline"
      :format="options.format"
      @finish="onFinish"
      :valueStyle="{ 'font-size': options.fontSize + 'px' }"
    />
  </div>
</template>

<script>
export default {
  props: ['options'],
  name: 'jCountdown',
  data() {
    return {
      deadline: Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30
    }
  },
  created() {
    this.initTime()
  },
  methods: {
    initTime() {
      let start = Date.now()
      if (this.options.unit == '天') {
        this.deadline = this.options.timeNum * 24 * 60 * 60 * 1000 + start
      } else if (this.options.unit == '时') {
        this.deadline = this.options.timeNum * 60 * 60 * 1000 + start
      } else if (this.options.unit == '分') {
        this.deadline = this.options.timeNum * 60 * 1000 + start
      }
    },
    onFinish() {
      console.log('finished!')
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.initTime()
      }
    }
  }
}
</script>

<style></style>
