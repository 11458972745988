<template>
  <div class="jDate">
    <a-date-picker
      class="antd-date"
      style="min-width: 130px; width: 100%"
      @change="dateChange($event, options)"
      show-time
      :placeholder="options.placeholder"
      v-model="dateValue"
      :format="options.format"
      :valueFormat="options.format"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { formatDate, getDateByDay } from '@/utils/jutil'
export default {
  name: 'jDate',
  props: ['value', 'options', 'disabled'],
  data() {
    return {
      dateValue: '',
      dates: []
    }
  },
  created() {
    if (this.options.getNowDate && !this.value) {
      this.dateValue = moment().format(this.options.format)
    } else {
      this.dateValue = this.value
    }
    this.dateChange(this.options.defaultValue, this.options)
  },
  mounted() {
    this.$nextTick(() => {
      let antd_dates = document.querySelectorAll('.jDate .antd-date')
      antd_dates.forEach((antd_date) => {
        antd_date.style = 'width: 100%'
      })
    })
  },
  methods: {
    dateChange(val, options) {
      let date = ''
      if (typeof val == 'string') {
        date = val
      } else {
        date = val.format(options.format)
      }
      let obj = {}
      if (options.model) {
        obj[options.model] = date
        this.$bus.$emit('em_dateChange', obj)
      }
    },
    setDayDates(date) {
      let updContent = []
      this.options.setDays?.forEach((res) => {
        if (!res.model) {
          return
        }
        updContent.push({
          model: res.model,
          context: getDateByDay(date, res.day, this.options.format)
        })
      })
      this.$bus.$emit('em_checkContent', updContent)
    }
  },
  watch: {
    dates: {
      deep: true,
      handler() {
        console.log(this.dates)
      }
    },
    dateValue() {
      this.setDayDates(this.dateValue)
      this.$emit('input', this.dateValue)
    },
    value() {
      this.dateValue = formatDate(this.value)
    }
  }
}
</script>

<style></style>
