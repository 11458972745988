var qiniu = require('qiniu-js') //引入 qiniu
import { qntoken } from '@/services/tokens'
import moment from 'moment'
export async function upload(file) {
  console.log('file', file)
  let company_uuid = localStorage.getItem('company_uuid')
  let tokenRes = await qntoken({
    act: 'upload',
    uuid: company_uuid,
    size: file.size
  })
  let { token, url } = tokenRes.data
  // let domain = JSON.parse(localStorage.getItem('admin.user')).domain
  let dateStr = moment().format('YYYY/MM/DD')
  let timestamp = new Date().getTime()
  let fileSuffix = file.name.substring(file.name.lastIndexOf('.'))
  // var key = domain + '/' + dateStr + '/' + timestamp + fileSuffix
  var key = dateStr + '/' + timestamp + fileSuffix
  var config = {
    uptoken: token,
    domain: url,
    unique_names: true
  }
  var putExtra = {
    fname: file.name,
    ...config
  }
  var observable = qiniu.upload(file, key, token, putExtra, config)
  return new Promise((resolve, reject) => {
    observable.subscribe({
      error(err) {
        reject(err)
      },
      complete(data) {
        data.path = url + '/' + data.key
        data.file = file
        resolve(data)
        localStorage.setItem('audioDatas', JSON.stringify(data))
      }
    }) // 上传开始
  })
}
