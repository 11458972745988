<template>
    <div class="audio">
        <div class="upload-area" v-if="!isShow">
            <a-input v-if="options.isText" v-model="urlText" placeholder="请填写音频地址"></a-input>
            <Upload v-else text="音频" type="audio" v-model="file" :disabled="options.disabled"/>
        </div>
        <div class="show-area" v-else>
            <audio
            v-if="url"
            :src="url"
            controls="controls" 
            :autoplay="options.autoplay" 
            :loop="options.loop"
            :muted="options.muted"
            style="max-width: 300px">
                您的浏览器不支持 音频 展示。
            </audio>
            <span v-else>未上传音频</span>
        </div>
    </div>
</template>

<script>
import Upload from '../Upload.vue'
export default {
    name: 'jAudio',
    components: {
        Upload
    },
    props: ['options', 'isShow', 'value', 'url'],
    data(){
        return {
          tableDisabled: '',//值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
          file: [],
          urlText: ''
        }
    },
    created(){
        //值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
        this.tableDisabled = localStorage.getItem('zSee')
        if(this.options.isText) {
            this.urlText = Array.isArray(this.value) ? '' : this.value
        } else {
            if(Array.isArray(this.value)){
                this.file = this.value
            }
        }
    },
    methods: {
    },
    watch: {
        file(val){
            this.$emit('input', val)
        },
        urlText(val){
            this.$emit('input', val)
        }
    },
}
</script>

<style lang="less" scoped>
    .controlAudio {
        width: 300px;
        height: 54px;
        border-radius: 45px;
        background-color: #e6e6e6;
    }
    .audio {
        width: 100%;
        .show-area {
            width: 100%
        }
    }
    .musicPlayer {
      width: 250px;
      height: 50px;
      background-color: #e6e6e6;
      border-radius: 45px;
    }
    #amr-play-record {
      margin: 2px 5px 0 3px;
    }
    #amr-record {
      margin-left: 6px;
    }
</style>