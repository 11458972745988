import moment from 'moment'
import {
  basicComponents,
  advancedComponents,
  relationComponents,
  showComponents,
  systemComponents,
  config,
  themes
} from '@/configJson/componentsConfig'
const configjson = {
  basicComponents,
  advancedComponents,
  relationComponents,
  showComponents,
  systemComponents,
  config,
  themes
}
//根据字段英文类型获取字段汉字类型
function getLabel(tag, type) {
  if (!tag) {
    return '---'
  }
  for (let elType in configjson) {
    for (let i = 0; i < configjson[elType].length; i++) {
      let element = configjson[elType][i]
      if (element.options.tag == tag && type == element.type) {
        return element.options.label
      }
    }
  }
  return ''
}
//删除对象中空的元素
function delUndefined(ob) {
  for (let e in ob) {
    if (typeof ob[e] === 'undefined' || ob[e] === null || ob[e] === '') {
      delete ob[e]
    } else if (ob[e].constructor === Object) {
      if (Object.keys(ob[e]).length === 0) {
        delete ob[e]
      } else {
        delUndefined(ob[e])
      }
    } else if (ob[e].constructor === Array) {
      ob[e].map(function (seg) {
        if (typeof seg === 'object') {
          delUndefined(seg)
        }
      })
    }
  }
  return ob
}
/** 数字金额大写转换(可以处理整数,小数,负数) */
// function smalltoBIG1(n) {
//   var fraction = ['角', '分']
//   var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
//   var unit = [
//     ['元', '万', '亿'],
//     ['', '拾', '佰', '仟']
//   ]
//   var head = n < 0 ? '欠' : ''
//   n = Math.abs(n)

//   var s = ''

//   for (var i = 0; i < fraction.length; i++) {
//     s += (
//       digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
//     ).replace(/零./, '')
//   }
//   s = s || '整'
//   n = Math.floor(n)

//   for (var i = 0; i < unit[0].length && n > 0; i++) {
//     var p = ''
//     for (var j = 0; j < unit[1].length && n > 0; j++) {
//       p = digit[n % 10] + unit[1][j] + p
//       n = Math.floor(n / 10)
//     }
//     s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
//   }
//   return (
//     head +
//     s
//       .replace(/(零.)*零元/, '元')
//       .replace(/(零.)+/g, '零')
//       .replace(/^整$/, '零元整')
//   )
// }
function smalltoBIG(str) {
  var num = parseFloat(str)
  var strOutput = '',
    strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分'
  num += '00'
  var intPos = num.indexOf('.')
  if (intPos >= 0) {
    num = num.substring(0, intPos) + num.substr(intPos + 1, 2)
  }

  strUnit = strUnit.substr(strUnit.length - num.length)
  for (var i = 0; i < num.length; i++) {
    strOutput +=
      '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i, 1), 1) + strUnit.substr(i, 1)
  }
  return strOutput
    .replace(/零角零分$/, '整')
    .replace(/零[仟佰拾]/g, '零')
    .replace(/零{2,}/g, '零')
    .replace(/零([亿|万])/g, '$1')
    .replace(/零+元/, '元')
    .replace(/亿零{0,3}万/, '亿')
    .replace(/^元/, '零元')
}
/**
 * 防抖
 *
 */
function debounce(fun, delay = 500) {
  let timer
  return function () {
    let self = this
    // 如果在setTimeout没有执行完成就再次触发了当前函数时就清除定时函数，取消执行（只执行最后一次）
    clearTimeout(timer)
    timer = setTimeout(() => {
      // 改变this指向（因为这里是属于window调用的fun，所以this会指向window），并且传入参数
      fun.apply(self, arguments)
    }, delay)
  }
}

/**
 * 解决js计算精度问题
 */
function newNumber(num, double = 5) {
  let newNum = Number(num)
  if (newNum) {
    return parseFloat(newNum.toFixed(double))
  } else {
    return num
  }
}
/**
 * 将无分割的日期转换成分割的日期 例如20220223转换为2022-02-23
 */
function formatDate(dateStr) {
  if (dateStr?.length == 8) {
    let year = dateStr.substring(0, 4)
    let month = dateStr.substring(4, 6)
    let day = dateStr.substring(6, 8)
    let date = year + '-' + month + '-' + day
    return date
  } else {
    return dateStr
  }
}

/**
 * 获取指定日期后某天的日期
 */
function getDateByDay(date, day, format) {
  let momentObj = moment(date, format)
  return momentObj.add(day, 'd').format(format)
}
/**
 * 获取传入的时间和当前时间是否为同一天
 */
function isSameDay(date) {
  date = moment(date).format('yyyy-MM-DD')
  let nowDate = moment().format('yyyy-MM-DD')
  if (date == nowDate) {
    return true
  }
  return false
}
/**
 * 根据当前页和页大小计算数据的序号
 */
function getNo(datas, page, pageSize, key = 'no') {
  // let no = page * pageSize - pageSize + 1;
  let no = 1
  datas.forEach((data) => {
    if (data.row == 'data' || !data.row) {
      data[key] = no
      no++
    }
    // data.randomId = Math.random();
  })
  return datas
}

const fileType = ['file', 'image']
//在批量动作可修改的情况下 可以编辑的字段类型
const supportEditFileds = [
  'input',
  'textarea',
  'date',
  'switch',
  'radio',
  'checkbox',
  'rate',
  'select',
  'district'
]
// 需要特殊回显的字段 如 多选框，单选框，下拉框
const dataOptionFileds = ['checkbox', 'radio', 'select']
// 无法在表格中展示的字段类型
const notShowFileds = ['table', 'collect', 'log']
//根据elements的字段生成antd的表格头
function createColumns(elements, unifyType) {
  let columns = []
  elements.forEach((res) => {
    let { options, type, edit_field, isShow, sorter } = res
    let title = options.unit
      ? options.label + `（${options.unit}）`
      : options.label
    if (fileType.includes(type)) {
      type = 'files'
    } else if (edit_field && supportEditFileds.includes(type)) {
      type = 'edit'
    } else if (dataOptionFileds.includes(type)) {
      type = 'dataOptions'
    } else if (notShowFileds.includes(type) && isShow) {
      type = 'notShow'
    }
    let colType = type
    if (unifyType) {
      type = unifyType
    }
    let columnd = {
      title,
      dataIndex: options.model,
      key: options.model,
      scopedSlots: { customRender: type },
      sorter,
      // width,
      colType,
      options,
      align: 'center',
      ellipsis: false
    }
    if (type == 'publish') {
      columnd.fixed = 'left'
      columnd.width = 120
    }
    if (type == 'action') {
      columnd.fixed = 'right'
      columnd.width = 120
    }
    if (type == 'actions') {
      columnd.key = ''
      columnd.fixed = 'right'
      columnd.width = 120
    }
    columns.push(columnd)
  })
  return columns
}

// 根据简单视图数据 查找完整字段
function findViewFields(elements, fields, showAction = true) {
  let views = []
  fields.forEach((field) => {
    let el = find(elements, field)
    if (el) {
      views.push(el)
    } else {
      views.push({
        type: field.name,
        sorter: field.order || false, //是否排序
        options: {
          label: field.title,
          model: field.name
        }
      })
    }
  })
  // views.unshift({
  // 	type: 'publish',
  // 	options: {
  // 		label: '状态',
  // 		model: 'publish',
  // 	}
  // })
  if (showAction) {
    views.push({
      type: 'action',
      options: {
        label: '操作',
        model: 'action'
      }
    })
  }
  function find(els, field) {
    let el = null
    if (!els) {
      return
    }
    els.forEach((e) => {
      if (e.options.model == field.name) {
        let resEl = JSON.parse(JSON.stringify(e))
        resEl.options.label = field.title
        el = resEl
      } else if (e.type == 'panel' && !el) {
        e.elements.forEach((panelEl) => {
          if (panelEl.options.model == field.name) {
            el = panelEl
          }
        })
      }
    })
    return el
  }
  return views
}
// 根据表单数据给分组行统计行等设置颜色
function setGroupStyle(formDatas, tableClassName = '.formDatas') {
  let bodys = document.querySelectorAll(tableClassName + ' .ant-table-tbody')
  let trs = {}
  bodys.forEach((body) => {
    body.childNodes?.forEach((tr, index) => {
      if (!trs[index]) {
        trs[index] = []
      }
      trs[index].push(tr)
    })
  })
  formDatas.forEach((row, index) => {
    trs[index]?.forEach((tr) => {
      tr.childNodes?.forEach((td, index) => {
        td.style = 'padding: 5px;text-align: center;'
      })
    })
    if (row.row == 'group') {
      trs[index].forEach((tr) => {
        tr.style = 'background-color: #fdfbf2;'
      })
    } else if (row.row == 'group_total') {
      trs[index].forEach((tr) => {
        tr.style = 'background-color: #f7f4fd;'
      })
    } else if (row.row == 'total') {
      trs[index].forEach((tr) => {
        tr.style = 'background-color: #FFC0C0;'
      })
    } else if (row.row == 'data') {
      trs[index].forEach((tr) => {
        let trStyle = tr.getAttribute('style')
        tr.style = trStyle + 'background-color: rgba(0,0,0,0);'
      })
    }
  })
}
// 查找子表类型字段
function findTable(els, model) {
  let el = null
  els.forEach((e) => {
    if (e.options.model == model) {
      el = e
    } else if (e.type == 'panel' && !el) {
      e.elements.forEach((panelEl) => {
        if (panelEl.options.model == model) {
          el = panelEl
        }
      })
    }
  })
  return el
}
export {
  getLabel,
  delUndefined,
  smalltoBIG,
  debounce,
  newNumber,
  formatDate,
  getDateByDay,
  isSameDay,
  createColumns,
  getNo,
  findViewFields,
  setGroupStyle,
  findTable
}
