<template>
  <div class="broadcast" :id="id">
    <div class="bro-box">
      <div class="title_icon">
        <span class="iconfont icon-laba_"></span>
      </div>
      <div class="context">
        <nobr class="text">{{ options.text }}</nobr>
        <div :style="{ width: p + 'px' }"></div>
        <nobr v-if="copyIsShow">{{ options.text }}</nobr>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'jBroadcast',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      id: 'a' + Math.floor(Math.random() * 1000),
      text: 'http://localhost:8080/#/dashboard/portal/porlist到结尾了',
      p: 20,
      copyIsShow: true
    }
  },
  mounted() {
    this.move()
  },
  methods: {
    move() {
      clearInterval(this.timer)
      let container = document.querySelector(`#${this.id} .bro-box .context`)
      let text = document.querySelector(`#${this.id} .bro-box .context .text`)
      if (!container || !text) {
        return
      }
      let conWidth = container.getBoundingClientRect().width
      let textWidth = text.getBoundingClientRect().width
      if (conWidth >= textWidth) {
        this.copyIsShow = false
        clearInterval(this.timer)
        return
      }
      let distance = 0 // 位移距离
      //设置位移
      this.timer = setInterval(() => {
        distance = distance - 1
        // 如果位移超过文字宽度，则回到起点
        if (-distance >= textWidth) {
          distance = this.p
        }
        text.style.marginLeft = distance + 'px'
      }, 20)
    }
  },
  watch: {
    'options.text': {
      handler() {
        console.log('执行了')
        this.move()
      }
    }
  },
  destroyed() {
    clearInterval(this.timer)
  }
}
</script>
<style scoped lang="less">
.broadcast {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  .bro-box {
    display: flex;
    align-items: center;
    .title_icon {
      background-color: #fff;
    }
    .context {
      flex: 1;
      overflow: hidden;
      display: flex;
      & > * {
        flex-shrink: 0;
      }
    }
  }
}
</style>
