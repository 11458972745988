<template>
  <div class="audio">
    <a-input-search
      v-model="searchVal"
      placeholder="请输入要搜索的内容"
      @search="onSearch"
    />
  </div>
</template>

<script>
export default {
  name: 'jSearch',
  props: ['options'],
  data() {
    return {
      searchVal: ''
    }
  },
  created() {},
  methods: {
    onSearch() {
      this.go()
    },
    go() {
      // this.$bus.$emit('app_goTo', {
      //     url: 'a2',
      //     params: {
      //         value: this.searchVal
      //     }
      // })
    }
  }
}
</script>

<style></style>
