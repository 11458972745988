
export default {
    namespaced: true,
    state: {
        //判断表单是否处于编辑状态
        isEdit: false,
        //当前正在编辑的表单基本配置信息
        formConfig: {},
        //当前最新的elements(表单组件)
        elements: [],
        //子表中添加的动态字段(子表组件)
        table_models: {},
        //表单需要打印的数据包括表头格式及其数据
        formDatas: JSON.parse(localStorage.getItem('formDatas')) || {
            fileds: [],
            datas: [],
        },
        //表单数据中涉及到的下拉选项
        options: {
            form: {}, //设计表单时自定义的选项
            groups: [],//所有部门
            users: []//所有用户
        },
		//报表打印存储数据
		reportPrintDatas:JSON.parse(localStorage.getItem('reportPrintDatas')) || {
        },
		//判断报表是否处于编辑状态
		isReportEdit:false,
		//判断仪表盘是否处于编辑状态
		isMeterEdit:false,
        //子表数据校验是否成功
        tabelCheckRes: true,
        //重复校验是否成功
        repCheckRes: true,
    },
    getters: {
        checkRes(state){
            return state.tabelCheckRes && state.repCheckRes
        }
    },
    mutations: {
        setOptions(state, res) {
            state.options = res
        },
        setIsEdit(state, res) {
            state.isEdit = res
        },
		setIsReportEdit(state, res) {
		    state.isReportEdit = res
		},
		setIsMeterEdit(state, res) {
		    state.isMeterEdit = res
		},
        setFormConfig(state, res) {
            state.formConfig = res
        },
        setElements(state, res) {
            state.elements = res
        },
        setModels(state, res) {
            Object.assign(state.table_models, res)
        },
        setTabelCheckRes(state, res) {
            state.tabelCheckRes = res
        },
        setRepCheckRes(state, res) {
            state.repCheckRes = res
        },
        ressetCheck(state) {
            state.tabelCheckRes = true
            state.repCheckRes = true
        },
        setFormDatas(state, res) {
            localStorage.setItem('formDatas', JSON.stringify(res))
            state.formDatas = res
        },
        clearFormDatas(state, res) {
            localStorage.removeItem('formDatas')
            state.formDatas = {}
        },
		setReportDatas(state, res) {
			localStorage.setItem('reportPrintDatas', JSON.stringify(res))
		    state.reportPrintDatas = res
		},
		clearReportDatas(state, res) {
		    localStorage.removeItem('reportPrintDatas')
		    state.reportPrintDatas = {}
		},
    }
}