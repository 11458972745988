<template>
  <div class="miniApp-formDatas">
    <a-modal v-model="newBuiltFormIsShow" width="100%" :footer="null">
      <newBuiltForm :formUuid = options.formUuid></newBuiltForm>
    </a-modal>
    

    <div>
      <div class="search-area">
      <a-input-search
        v-model="searchVal"
        placeholder="请输入要搜索的内容"
        @search="onSearch"
      />
    </div>
    <div class="data-list beauty-scroll" :ref="scrollName">
      <a-button type="primary" class="newBuilt" @click="newBuilt">新建</a-button>
      <div class="isNull" v-if="showDataList.length === 0">暂无数据</div>
      <div
        class="data-item"
        v-for="(data, index) in showDataList"
        :key="data.id"
        @click="toDetail(data.id)"
      >
        <div class="no">
          <h3>{{ index + 1 }}</h3>
        </div>
        <div class="info">
          <div class="top info-box">
            <div class="title">
              <j-text>{{ getData(data, 0) }}</j-text>
            </div>
            <a-icon type="right"></a-icon>
          </div>
          <div class="footer info-box">
            <div class="date">
              <!-- <a-icon type="calendar" /> -->
              {{ getData(data, 1) }}
            </div>
            <div class="user">
              <!-- <a-icon type="user"></a-icon>  -->
              {{ getData(data, 2) }}
            </div>
          </div>
        </div>
      </div>
      <div class="loading" style="text-align: center" v-if="loading">
        <a-icon type="loading" /> 加载中...
      </div>
      <div class="isNull" v-if="isNull && showDataList.length !== 0">
        没有更多数据了
      </div>
    </div>
    </div>
    
  </div>
</template>
<script>
import { viewAndForm } from '@/services/protal'

import newBuiltForm from './newBuiltForm.vue'
export default {
  name: 'jDataList',
  props: ['options'],
  components: { newBuiltForm},
  data() {
    return {
      newBuiltFormIsShow: false,
      keyWord: '',
      searchVal: '',
      page: 1,
      count: 0,
      dataList: [],
      showDataList: [],
      viewFields: [],
      scrollName: 'scorll_' + this.options.model,
      scrollEL: {},
      loading: false,
      isNull: false
    }
  },
  created() {
    this.$bus.$emit('em_miniSetTitle', '数据列表')
  },
  mounted() {
    this.scrollEL = this.$refs[this.scrollName]
    this.scrollEL.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 新建
    newBuilt() {
      this.newBuiltFormIsShow = true
    },




    handleScroll() {
      let scrollTop = this.scrollEL.scrollTop
      let offsetHeight = this.scrollEL.offsetHeight
      let scrollHeight = this.scrollEL.scrollHeight
      if (scrollTop + offsetHeight === scrollHeight) {
        this.pushData()
      }
    },
    pushData() {
      this.loading = true
      if (this.dataList.length > 0) {
        setTimeout(() => {
          this.showDataList = this.showDataList.concat(
            this.dataList.splice(0, this.options.dataSize)
          )
          this.loading = false
        }, 600)
      } else if (!this.isNull) {
        if (this.showDataList.length + this.dataList.length === this.count) {
          this.isNull = true
          return
        }
        this.page += 1
        this.getDataList()
      } else {
        this.loading = false
      }
    },
    onSearch(val) {
      if (!this.keyWord) {
        return
      }
      this.scrollEL.scrollTop = 0
      this.isNull = false
      this.page = 1
      let where = {
        search_action: 'search',
        keyType: this.keyWord,
        keyWord: val,
        search_field_opt: 'like'
      }
      this.where = where
      this.getDataList()
    },
    toDetail(data_id) {
      this.$bus.$emit('app_goTo', {
        url: 'detail',
        params: {
          form_uuid: this.options.formUuid,
          data_id
        }
      })
    },
    getDataList() {
      let { formUuid, viewId } = this.options
      if (formUuid && viewId) {
        let params = {
          view_id: viewId,
          page: this.page
        }
        if (this.where) {
          Object.assign(params, this.where)
        }
        viewAndForm(formUuid, params).then((res) => {
          let { data, fields, count } = res.data.data
          this.count = count
          this.dataList = data
          if (this.page === 1) {
            this.showDataList = this.dataList.splice(0, this.options.dataSize)
          } else if (this.dataList.length > 0) {
            this.showDataList = this.showDataList.concat(
              this.dataList.splice(0, this.options.dataSize)
            )
          }
          this.viewFields = fields
          if (fields.length > 0) {
            this.keyWord = fields[0].name
          }
          this.loading = false
        })
      } else {
        this.dataList = []
        this.showDataList = []
        this.viewFields = []
      }
    },
    getData(row, index) {
      let field = this.viewFields[index]
      if (field && row) {
        // return `${field.title}:${row[field.name]}`
        return `${row[field.name]}`
      }
      return ''
    }
  },
  watch: {
    options: {
      immediate: true,
      deep: true,
      handler() {
        this.getDataList()
      }
    },
    searchVal: {
      handler(val) {
        if (!val) {
          this.page = 1
          this.where = null
          this.isNull = false
          this.scrollEL.scrollTop = 0
          this.getDataList()
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.miniApp-formDatas {
  .search-area {
    margin-bottom: 10px;
  }
  .data-list {
    max-height: 30rem;
    overflow-y: auto;
    .data-item {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      background-color: #fff;
      padding: 10px;
      padding-left: 0px;
      border-radius: 3px;
      margin-bottom: 10px;
      cursor: pointer;
      .no {
        margin: 0 10px;
      }
      .info {
        flex: 1;
        .info-box {
          width: 100%;
          justify-content: space-between;
          display: flex;
          .title {
            flex: 1;
            width: 80px;
          }
        }
        .top {
          margin-bottom: 5px;
        }
        .footer {
          .date {
            color: #9e9e9e;
          }
        }
      }
    }
  }
}
.newBuilt {
		margin: 2px 0 8px 4px;
	}
</style>
