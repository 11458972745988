<template>
  <div class="j-title">
    <div
      class="title"
      v-if="options.isHideLine"
      :style="{ textAlign: options.ori }"
    >
      <span class="block"> <a-icon type="pushpin" /> {{ options.label }} </span>
    </div>
    <a-divider v-else :orientation="options.ori" :dashed="options.isDashed">{{
      options.label
    }}</a-divider>
  </div>
</template>
<script>
export default {
  name: 'jTitle',
  props: ['options'],
  computed: {
    styleVar() {
      return {
        '--text-ori': this.options.ori
      }
    }
  }
}
</script>
<style lang="less" scoped>
.j-title {
  .title {
    color: #fff;
    line-height: 30px;
    background-color: rgba(13, 179, 166, 0.5);
    border-radius: 5px;
    overflow: hidden;
    .block {
      display: inline-block;
      padding: 0 10px;
      background-color: rgba(13, 179, 166, 1);
    }
  }
}
</style>
