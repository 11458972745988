<template>
  <a-input
    :disabled="options.disabled"
    v-model="context"
    @change="contextChange"
    :placeholder="options.placeholder"
    type="textarea"
  />
</template>

<script>
import { findTable } from '@/utils/jutil'
import { mapState } from 'vuex'
import RegEx from '@/configJson/regEx'
export default {
  props: ['options', 'value'],
  computed: {
    ...mapState('self', ['elements'])
  },
  name: 'jTextarea',
  data() {
    return {
      context: '',
      titleList: []
    }
  },
  created() {
    this.context = this.value
    if (this.context && this.options.checkContext) {
      this.filterContext()
    }
  },
  methods: {
    getTitle(elements) {
      elements.forEach((res) => {
        if (res.options.model != this.options.model) {
          this.titleList.push({
            title: res.options.label,
            model: res.options.model,
            context: '',
            firstIndex: -1, //标题第一个字的下标
            startIndex: -1, //标题末尾的下表（包括标题后的中英冒号以及空格）
            endIndex: -1
          })
        }
        if (res.type == 'panel') {
          this.getTitle(res.elements)
        }
      })
    },
    contextLook() {
      this.titleList = []
      let elements = this.elements
      // 如果是子表就从子表中找标题
      if (this.options.isTable) {
        let tableEl = findTable(elements, this.options.isTable)
        elements = tableEl?.options?.tables?.elements || []
      }
      this.getTitle(elements)
      this.titleList.forEach((title) => {
        let start = this.context.indexOf(title.title)
        if (start != -1) {
          title.firstIndex = start
          start = title.title.length + start
          let nextStr = this.context.charAt(start)
          if (nextStr == ':' || nextStr == '：' || nextStr == ' ') {
            start += 1
          }

          title.startIndex = start
          let end = -1
          let end1 = this.context.indexOf('；', start)
          let end2 = this.context.indexOf(';', start)
          if (end1 != -1 && end2 != -1) {
            end = end1 < end2 ? end1 : end2
          } else if (end1 != -1) {
            end = end1
          } else if (end2 != -1) {
            end = end2
          }
          title.endIndex = end
        }
      })
    },
    filterContext() {
      let titleList = this.maopao()
      titleList.forEach((res, index) => {
        let nextTitle = titleList[index + 1]
        if (nextTitle) {
          if (res.endIndex == -1 || res.endIndex > nextTitle.firstIndex) {
            res.endIndex = nextTitle.firstIndex
          }
        }
      })
      titleList.forEach((res) => {
        let result = ''
        if (res.endIndex == -1) {
          result = this.context.substring(res.startIndex)
        } else {
          result = this.context.substring(res.startIndex, res.endIndex)
        }
        res.context = result.trim()
      })
      // 如果是子表中的字段就更新子表内的数据
      if (this.options.isTable) {
        this.updTableData(titleList)
      } else {
        //用字符截取过滤
        this.$bus.$emit('em_checkContent', titleList)
      }
      //用正则过滤(正则更准一些 所以用正则再过滤一遍)
      this.getTitle(this.elements)
      let regResult = this.regCheck(this.titleList)
      // 如果是子表中的字段就更新子表内的数据
      if (this.options.isTable) {
        this.updTableData(regResult)
      } else {
        //用字符截取过滤
        this.$bus.$emit('em_checkContent', regResult)
      }
    },
    updTableData(datas) {
      let data = {}
      datas.forEach((res) => {
        data[res.model] = res.context
      })
      this.$bus.$emit(this.options.isTable + 'em_relValue', {
        index: this.options.index,
        datas: data
      })
    },
    //根据找到的标题下表进行从小到大排序
    maopao() {
      this.contextLook()
      //过滤掉没有找到的标题
      let arr = this.titleList.filter((res) => {
        return res.firstIndex != -1
      })
      let len = arr.length
      for (let i = 0; i < len - 1; i++) {
        for (let j = i + 1; j < len; j++) {
          if (arr[i].firstIndex > arr[j].firstIndex) {
            let temp = arr[i]
            arr[i] = arr[j]
            arr[j] = temp
          }
        }
      }
      return arr
    },
    regCheck(titleList) {
      let result = []
      titleList.forEach((res) => {
        let texts = []
        if (res.title == '车牌号') {
          texts = this.context.match(RegEx.CarNumber)
        }
        if (texts) {
          if (texts.length > 0) {
            res.context = texts[0]
            result.push(res)
          }
        }
      })
      return result
    },
    contextChange() {
      this.$emit('input', this.context)
      if (this.options.checkContext) {
        this.filterContext()
      }
    }
  },
  watch: {
    value() {
      this.context = this.value
      if (this.options.checkContext) {
        console.log(this.context)
        this.filterContext()
      }
    }
  }
}
</script>

<style></style>
