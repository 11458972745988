<template>
  <div class="lookForm">
    <a-form-model
      :layout="config.layout"
      ref="lookForm"
      :rules="rules"
      :model="models"
    >
      <a-row v-if="tabs.length == 0" class="cont">
        <a-col
          style="padding: 0 5px"
          :span="24"
          :key="index"
          v-for="(element, index) in elements"
        >
          <template v-if="element.type == 'panel'">
            <template
              v-if="
                element.options.showType == '1' ||
                (element.options.showType == '2' &&
                  models[element.options.field])
              "
            >
              <a-row :key="index" class="cont">
                <a-col v-for="(el, i) in element.elements" :key="i" :span="24">
                  <!-- <look-form-item
                    v-if="!el.isShow"
                    :formID="config.formID"
                    :el="el"
                    :models="models"
                    @dataUpd="dataUpd"
                  />
                  <show-item v-else :models="models" :element="el" /> -->
                </a-col>
              </a-row>
            </template>
          </template>
          <template v-else>
            <look-form-item
              :tabChangeValue="tabChangeValue"
              :getFindDatas="getFindDatas"
              :formID="config.formID"
              v-if="!element.isShow"
              :key="index"
              :el="element"
              :models="models"
              @dataUpd="dataUpd"
            />
            <show-item v-else :models="models" :element="element" />
          </template>
        </a-col>
      </a-row>
      <a-tabs v-else type="card" style="height: 100%; margin-bottom: 0px">
        <a-tab-pane key="-1" tab="首页" class="cont">
          <a-col
            style="padding: 0 5px"
            :span="24"
            :key="index"
            v-for="(element, index) in filterShow(elements)"
          >
            <template v-if="element.type == 'panel'">
              <template
                v-if="
                  element.options.showType == '1' ||
                  (element.options.showType == '2' &&
                    models[element.options.field])
                "
              >
                <a-row :key="index" class="cont">
                  <a-col
                    v-for="(el, i) in element.elements"
                    :key="i"
                    :span="24"
                  >
                    <!-- <look-form-item
                      v-if="!isShow"
                      :formID="config.formID"
                      :el="el"
                      :models="models"
                      @dataUpd="dataUpd"
                    /> -->
                  </a-col>
                </a-row>
              </template>
            </template>
            <template v-else>
              <look-form-item
                :tabChangeValue="tabChangeValue"
                :getFindDatas="getFindDatas"
                :formID="config.formID"
                v-if="!element.isShow"
                :key="index"
                :el="element"
                :models="models"
                @dataUpd="dataUpd"
              />
              <!-- <show-item v-else :models="models" :element="element" /> -->
            </template>
          </a-col>
        </a-tab-pane>
        <a-tab-pane
          v-for="(el, index) in tabs"
          :key="index"
          :tab="el.options.label"
        >
          <a-row class="cont">
            <a-col
              style="padding: 0 5px"
              :span="24"
              :key="index"
              v-for="(element, index) in el.elements"
            >
              <look-form-item
                :tabChangeValue="tabChangeValue"
                :getFindDatas="getFindDatas"
                :formID="config.formID"
                v-if="!element.isShow"
                :key="index"
                :el="element"
                :models="models"
                @dataUpd="dataUpd"
              />
              <!-- <show-item v-else :models="models" :element="element" /> -->
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
      <slot name="diy"></slot>
    </a-form-model>
  </div>
</template>
<script>
import { initRules } from '@/utils/createRules'
import { mapState, mapMutations, mapGetters } from 'vuex'
import LookFormItem from './LookFormItem'
import ShowItem from './ShowItem.vue'
export default {
  components: { LookFormItem, ShowItem },
  props: {
    tabChangeValue: {
      type: String
    },
    getFindDatas: {
      type: Array
    },
    form: {
      type: Object,
      default: () => {}
    },
    dataModels: {
      type: Object,
      default: () => {}
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: {},
      elements: [],
      models: {},
      rules: {},
      tabs: []
    }
  },
  computed: {
    ...mapState('self', ['table_models']),
    ...mapGetters('self', ['checkRes'])
  },
  created() {
    this.ressetCheck()
    this.$bus.$on('em_relValue', (res) => {
      this.relValue(res)
    })
    //设置指定model的值 [{model: model值,context: 要设置的内容}]
    this.$bus.$on('em_checkContent', (res) => {
      res.forEach((res) => {
        if (res.model) {
          this.$set(this.models, res.model, res.context)
        }
      })
    })
  },
  mounted() {
    this.config = this.form.config
    this.elements = JSON.parse(JSON.stringify(this.form.elements))
    this.getModels(this.elements)
    this.rules = initRules(this.elements)
    this.checkTab()
    this.$nextTick(() => {
      this.$refs.lookForm?.clearValidate()
    })
  },
  methods: {
    ...mapMutations('self', ['setTabelCheckRes', 'ressetCheck']),
    checkTab() {
      let tabs = []
      this.elements.forEach((el) => {
        if (el.options.showType == '3') {
          tabs.push(el)
        }
      })
      this.tabs = tabs
    },
    getModels(elements) {
      if (this.dataModels) {
        this.models = this.dataModels
        return
      }
      elements.forEach((res) => {
        if (res.type == 'panel') {
          this.getModels(res.elements)
        } else {
          this.$set(this.models, res.options.model, res.options.defaultValue)
        }
      })
    },
    getData() {
      this.$bus.$emit('em_fieldCheck')
      let models = null
      return new Promise((resolve) => {
        setTimeout(() => {
          this.$refs.lookForm.validate((valid) => {
            if (valid) {
              models = this.models
              // for (let key in models) {
              //   if (this.table_models[key]) {
              //     models[key] = this.table_models[key]
              //   }
              // }
            } else {
              this.$message.warning(`表单填写有误，请检查！`)
              this.setTabelCheckRes(true)
            }
          })
          resolve(models)
        }, 0)
      })
    },
    dataUpd(field) {
      let key = Object.keys(field)[0]
      this.$set(this.models, key, field[key])
      this.models[key] = field[key]
      if (!Array.isArray(field[key]) || !field[key].includes('')) {
        this.$refs.lookForm.validateField(key)
      }
      this.$forceUpdate()
    },
    //执行关联取值
    relValue(source) {
      for (let key in source) {
        if (this.models[key] || this.models[key] == '') {
          this.$set(this.models, key, source[key])
        }
      }
      this.$forceUpdate()
    },
    //过滤掉不需要在首页标签页显示的折叠面板
    filterShow(elements) {
      return elements.filter(
        (e) => !(e.type == 'panel' && e.options.showType == '3')
      )
    }
  },
  destroyed() {
    this.ressetCheck()
  },
  watch: {
    dataModels: {
      deep: true,
      handler() {
        this.models = this.dataModels
      }
    },
    models: {
      deep: true,
      handler() {
        this.$bus.$emit('em_formValueChange', this.models)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.lookForm {
  width: 100%;
}
.cont {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .ant-tabs {
  .ant-tabs-bar {
    margin-bottom: 0px !important;
  }
}
/deep/ .ant-form-item-control .ant-form-explain {
  position: absolute;
  right: 0px;
  bottom: -13px;
}
</style>
