<template>
  <div class="warn">
    <div
      v-for="warn in options.warnVal"
      :key="warn.color"
      class="box"
      :style="{ 'background-color': warn.color }"
      v-show="warn.title == activeTitle"
    >
      {{ warn.title
      }}{{
        options.showDay
          ? `(${day >= 0 ? '剩余' + day + '天' : '已过期' + day * -1 + '天'})`
          : ''
      }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'jWarn',
  props: {
    value: {
      type: String,
      default: '2021-11-24'
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activeTitle: '',
      day: 0,
      show: false
    }
  },
  created() {
    this.dayNum()
    this.$bus.$on('em_dateChange', (res) => {
      if (res[this.options.field]) {
        this.$emit('input', res[this.options.field])
      }
    })
  },
  methods: {
    dayNum() {
      let newDate = ''
      if (this.value) {
        newDate = moment(this.value).format('YYYY-MM-DD')
      } else {
        newDate = moment(new Date()).format('YYYY-MM-DD')
      }
      let nowDate = moment(new Date()).format('YYYY-MM-DD')
      this.day = moment(newDate).diff(moment(nowDate), 'days')
      this.options.warnVal.forEach((res, index) => {
        if (index == 0) {
          if (
            this.day >= res.value ||
            (this.day > this.options.warnVal[index + 1].value &&
              this.day <= res.value)
          ) {
            this.activeTitle = res.title
          }
        } else if (this.options.warnVal.length - 1 == index) {
          if (this.day <= res.value) {
            this.activeTitle = res.title
          } else if (
            this.day < this.options.warnVal[index - 1].value &&
            this.day >= res.value
          ) {
            this.activeTitle = this.options.warnVal[index - 1].title
          }
        } else {
          if (
            this.day > this.options.warnVal[index + 1].value &&
            this.day <= res.value
          ) {
            this.activeTitle = res.title
          }
        }
      })
    }
  },
  watch: {
    warnVal: {
      deep: true,
      handler() {
        this.dayNum()
      }
    },
    value(value) {
      this.dayNum()
    }
  }
}
</script>

<style lang="less" scoped>
.warn {
  .box {
    width: 100%;
    min-height: 30px;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
  }
}
</style>
