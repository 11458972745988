<template>
  <div class="showItem">
    <template v-if="element.type == 'input'">
      <input type="hidden" :value="element.defaultValue" />
    </template>
    <template v-if="element.type == 'title'">
      <j-title :options="element.options"></j-title>
    </template>
    <template v-if="element.type == 'countdown'">
      <j-countdown :options="element.options" />
    </template>
    <template v-if="element.type == 'audio'">
      <j-audio :options="element.options" />
    </template>
    <template v-if="element.type == 'video'">
      <j-video :options="element.options" />
    </template>
    <template v-if="element.type == 'carousel'">
      <j-carousel :options="element.options" />
    </template>
    <template v-if="element.type == 'inLink'">
      <p class="title">{{ element.options.label }}:</p>
      <iframe
        :src="element.options.url"
        width="100%"
        style="min-height: 250px"
      ></iframe>
    </template>
    <template v-if="element.type == 'outLink'">
      <a :href="element.options.url" :target="element.options.atarget">{{
        element.options.label
      }}</a>
    </template>
    <template v-if="element.type == 'rqcode'">
      <p class="title">{{ element.options.label }}:</p>
      <j-qrcode :options="element.options" />
    </template>
    <template v-if="element.type == 'formula'">
      <j-formula
        :options="element.options"
        :value="models[element.options.model]"
      />
    </template>
    <template v-if="element.type == 'strCheck'">
      <p class="title">{{ element.options.label }}:</p>
      <strCheck :options="element.options" />
    </template>
  </div>
</template>

<script>
import strCheck from '@/pages/form/components/bdocr/StrCheck.vue'
export default {
  components: {strCheck},
  props: ['element', 'models'],
  data() {
    return {
      date: '',
      model: ''
    }
  },
  created() {
    // this.$bus.$on('em_dateChange', res => {
    //     if(res[this.element.options.field]){
    //         this.date = res[this.element.options.field]
    //     }
    // })
  }
}
</script>

<style lang="less" scoped>
@import './css/show';
.title {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
}
</style>
