export default {
  namespaced: true,
  state: {
    miniAppConfig: {
      menus: [],
      components: {}
    },
    urlHistory: [
      // 记录迷你应用点击过的url（也就是页面uuid）
    ],
    searchDatas: [],  //查询后，获得的数据
    clickFindBtn: false,  //判断用户是否点击了查询按钮
  },
  getters: {
    getUrlHistory(state) {
      return state.urlHistory[state.urlHistory.length - 1]
    }
  },
  mutations: {
    setMiniAppConfig(state, res) {
      state.miniAppConfig = res
    },
    addUrlHistory(state, res) {
      let index = state.urlHistory.findIndex((res) => res.url === res)
      if (index >= 0) {
        state.urlHistory.splice(index, 1)
      }
      state.urlHistory.push(res)
    },
    backHistory(state) {
      state.urlHistory.pop()
    },
    // 更新查询的数据
    editSearchDatas(state, res) {
      state.searchDatas = res
    },
    // 判断用户是否点击了查询按钮
    editClickFindBtn(state, res) {
      state.clickFindBtn = res
    }
  },
  actions: {}
}
