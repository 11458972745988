import Cookie from 'js-cookie'
const methods = {
  install: function (Vue) {
    Vue.mixin({
      methods: {
        $goToLogin() {
          Vue.prototype.$bus.$emit('app_goTo', {
            url: 'login'
          })
          Cookie.remove('AUTHORIZATION')
          localStorage.removeItem('userInfo')
        }
      }
    })
  }
}

export default methods
