<template>
    <div class="fileUpload">
        <a-upload 
        :multiple="true"
        :list-type="options.tag == 'DoImage' ? 'picture' : 'text'"
        :file-list="fileList" 
        :remove="handleRemove" 
        :before-upload="beforeUpload"
        class="upload-list-inline">
            <a-button type="link" :disabled="options.disabled || tableDisabled == 1" style="padding-left: 0px"> 选择{{ options.tag == 'DoImage' ? '图片' : '文件' }} </a-button>
        </a-upload>
        <showFiles :datas="fileList" :isEdit="!options.disabled" @del="handleRemove"/>
    </div>
</template>

<script>
import {upload} from '@/utils/upload'
import showFiles from '@/pages/form/components/showFiles/Files.vue'
export default {
    components: {showFiles},
    name: 'jFileUpload',
    props: ['options', 'value'],
    data() {
        return {
            tableDisabled: '',//值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
            fileList: [],
            tempFiles: [],
        }
    },
    created(){
        //值为1，代表用户在查看子表，此时，子表不可以使用添加或者删除功能
        this.tableDisabled = localStorage.getItem('zSee')
        if(Array.isArray(this.value)) {
            this.fileList = this.value
            this.fileList.forEach((file, index) => {
                if(!file.name){
                    file.name = '-'
                }
                file.uid = (index+1) * -1
            })
        }
        this.tempFiles = this.fileList
    },
    methods: {
        handleRemove(file) {
            let isDel = !this.options.disabled
            if(isDel){
                const index = this.fileList.findIndex(f => {
                    return f.uid == file.uid
                })
                const tempIndex = this.tempFiles.findIndex(f => {
                    return f.uid == file.uid
                })
                const newFileList = this.fileList.slice();
                const tempFiles = this.tempFiles.slice();
                newFileList.splice(index, 1);
                tempFiles.splice(tempIndex, 1);
                this.fileList = newFileList;
                this.tempFiles = tempFiles;
                this.$emit('input', this.tempFiles)
            }
            return isDel
        },
        check(file){
            let start = file.name.lastIndexOf('.')
            let suffix = file.name.substring(start)
            if((file.size / 1024  / 1024) > this.options.fileSize){
                this.$message.error('文件大小不能超过' + this.options.fileSize + 'MB')
                return false
            } else if(this.fileList.length >= this.options.fileNum) {
                this.$message.error('最多只能上传' + this.options.fileNum + '个文件')
                return false
            }
            if(this.options.tag == 'DoImage'){
                if(this.options.support.indexOf(suffix) == -1) {
                    this.$message.error('文件格式仅支持：' + this.options.support.join('，'), 5)
                    return false
                }
            }
            return true
        },
        beforeUpload(file) {
            if(this.check(file)){
                if(this.options.isTable){ //如果当前上传文件属于子表那点击后就直接上传七牛云
                    upload(file).then((res) => {
                        let resFile = {  
                            uid: res.file.uid,
                            name: res.file.name,
                            status: 'done',
                            url: res.key,
                            size: res.file.size
                        }
                        this.tempFiles =[...this.tempFiles, resFile]
                        this.$emit('input', [...this.tempFiles])
                    }).catch((e) => {
                        this.$message.error('文件上传失败')
                    })
                } else {
                    this.tempFiles =[...this.tempFiles, file]
                    this.$emit('input', this.tempFiles)
                }
                this.fileList = [...this.fileList, file];
            } 
            return false;
        }
    }
}
</script>

<style scoped>
/deep/ .ant-upload-list {
    display: none;
}
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>