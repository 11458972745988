import RegEx from '@/configJson/regEx'
let rules = {}
export function initRules(elements, reset = true) {
  if (reset) {
    rules = {}
  }
  elements.forEach((element) => {
    if (element.type == 'panel') {
      initRules(element.elements, false)
    } else {
      element.rules = []
      validateRequired(element)
      validatePattern(element)
      validateLength(element)
      rules[element.options.model] = element.rules
    }
  })
  return rules
}
function validateRequired(element) {
  if (element.options?.required && !element.options.disabled) {
    if (element.type == 'location') {
      // element.rules.push({ required: true, message: `${element.options.label}必须填写`, trigger: 'change'})
    } else {
      element.rules.push({
        required: true,
        message: `${element.options.label}必须填写`,
        trigger: 'blur'
      })
    }
  }
}
function validatePattern(element) {
  if (element.options?.tag && !element.options.disabled) {
    let pattern = RegEx[element.options.tag]
    if (pattern) {
      element.rules.push({
        pattern,
        message: `${element.options.label}格式有误`,
        trigger: 'blur'
      })
    }
  }
}
function validateLength(element) {
  if (element.options?.required && !element.options.disabled) {
    let isIntNum = element.options.isIntNum
    if (isIntNum && isIntNum > 0) {
      let pattern = new RegExp('^(-?\\d{0,' + isIntNum + '})?$')
      element.rules.push({
        pattern,
        message: `${element.options.label}整数部分不能超过${isIntNum}位`,
        trigger: 'blur'
      })
    }
  }
}
