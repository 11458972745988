<template>
  <div class="actionFrom">
    <a-spin class="isLoading" :spinning="formLoading" />
    <Theme
      :distinguish = "distinguish"
      :form="form"
      :config="form.config"
      :lookForm="true"
      @submit="saveData"
      v-if="!formLoading"
    />
  </div>
</template>

<script>
import { viewTable, addFormDataz } from '@/services/form'
import Theme from '@/pages/form/components/Theme.vue'
export default {
  components: { Theme },
  props: { 
    formUuid: {
      type: String
    }
   },
  data() {
    return {
      distinguish: '',  //区分是查询表单(1)还是编辑表单(2)
      form: [],
      formLoading: false,
      company_uuid: '',
    }
  },
  created() {
    this.distinguish = '2'
    this.init()
  },
  methods: {

    // 初始化表单元素
    init() {
      this.formLoading = true
      viewTable({uuid: this.formUuid}).then( res => {
        let { data } = res.data
        this.form = data
        this.formLoading = false
      })
    },



    // 保存
    saveData(data) {
      let obj = {}
      obj.data = data
      obj.formID = this.formUuid
      addFormDataz(
        this.formUuid, obj
      )
        .then((res) => {
          localStorage.removeItem('audioDatas')
          localStorage.removeItem('isAudio')
          const { code } = res.data
          if (code === 0) {
            this.$message.success('添加成功！')
            setTimeout(() => {
              this.$router.go(0)
            }, 500)
          } else {
            this.$message.error('添加失败！')
          }
        })
        .catch(() => {
          this.$message.error('系统内容错误！')
          localStorage.removeItem('audioDatas')
          localStorage.removeItem('isAudio')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.actionFrom {
  width: 100%;
  height: 100%;

  .content {
    min-height: 100vh;
  }
}
</style>
