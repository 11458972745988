<template>
  <div class="carousel">
    <div class="null" v-if="options.images.length == 0">
      <span>暂无图片</span>
    </div>
    <a-carousel v-else :autoplay="options.autoplay">
      <div v-for="(image, index) in options.images" :key="index">
        <img :src="image.url" width="100%" height="100%" />
      </div>
    </a-carousel>
  </div>
</template>

<script>
export default {
  name: 'jCarousel',
  props: ['options'],
  watch: {
    options: {
      deep: true,
      handler() {
        this.$forceUpdate()
      }
    }
  }
}
</script>

<style lang="less" scoped>
/* For demo */
/deep/ .ant-carousel {
  .slick-slide {
    text-align: center;
    height: 260px;
    overflow: hidden;
    line-height: 260px;
  }
}
.carousel {
  .null {
    height: 120px;
    text-align: center;
    line-height: 120px;
    color: #ccc;
    font-size: 16px;
  }
}
</style>
