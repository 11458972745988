<template>
    <div class="showFiles">
        <div class="fiels">
            <span v-for="file,index in files" :key="index">
                <a :href="file.url" target="_blank" title="点击进行下载" class="file" download="file.txt">{{file.name}} &nbsp;</a>
                <a-icon v-if="isEdit" type="delete" style="color: red; margin-right: 2px" @click="delFile(file)"></a-icon>
                <template v-if="index != (files.length-1)">，</template>
            </span>
        </div>
        <div class="images">
            <viewer :images="images">
                <span v-for="(img, index) in images" :key="index">
                    <img :src="img.url" width="90px" height="60px" title="点击进行查看"/>
                    <a-icon v-if="isEdit" type="delete" style="color: red;margin-right: 2px" @click="delFile(img)"></a-icon>
                </span>
            </viewer>
        </div>
    </div>
</template>
<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
export default {
    name: 'jFiles',
    props: ['datas', 'isEdit'],
    data() {
        return {
            determineType: '',
            files: [],
            images: [],
            imgType: ['jpg','jpeg','png','gif']
        }
    },
    created(){
        this.init()
        // console.log('images', this.images)
    },
    methods: {
        init(){
            this.files=[]
            this.images=[]
            if(!Array.isArray(this.datas)) return
            this.datas.forEach(file => {
                // console.log('pc端与小程序上传的图片在这看：file', file)
                // 如果file的url里面有'jpg','jpeg','png','gif'，就判定这是一张图片，就给他的name属性赋值，确保微信小程序上传的图片在pc端可以显示
                if (file.url) {
                    let determine = file.url.substring(file.url.lastIndexOf('.')+1)
                    
                    this.determineType = determine.substring(0,3)
                    // console.log('this.determineType', this.determineType)
                    if (this.imgType.indexOf(this.determineType) != -1) {
                        let math = Math.floor(Math.random()*(100-1)+ 1)
                        file.name = math + '.' + this.determineType
                    }
                }

                if(file.name && this.isImg(file.name)){
                    if(file.constructor == File){
                        getBase64(file, (imgUrl) => {
                            file.url = imgUrl
                            this.images.push(file)
                        })
                    } else {
                        this.images.push(file)
                    }
                } else if(file.name){
                    this.files.push(file)
                }
            });
        },
        isImg(fileName){
            let suffix = fileName.substring(fileName.lastIndexOf('.')+1)
            // console.log('suffix', suffix)
            if(this.imgType.indexOf(suffix) != -1){
                // console.log('this.imgType.indexOf(suffix)', this.imgType.indexOf(suffix))
                return true
            }
            return false
        },
        downloadFile({url, name}){
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(url));
            element.setAttribute('download', name);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        delFile(file){
            this.$emit('del', file);
        }
    },
    watch: {
        datas: {
            // deep: true,
            deep: false,
            handler() {
                this.init()
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .showFiles {
        img{
            cursor: pointer;
            margin-right: 2px;
            margin-bottom: 2px;
            border-radius: 3px;
        }
        .fiels {
            display: flex;
            margin-bottom: 5px;
        }
        .images {
            display: flex;
        }
    }

</style>