import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const routes = [
  {
    path: '/portal',
    name: 'Index',
    component: () => import('@/components/portal/entity/MiniApp.vue')
  },
  {
    path: '/actionform/:company_uuid/form/:uuid',
    name: '表单填写',
    component: () => import('@/pages/form/ActionForm.vue')
  },
  {
    path: '/searchdata/:company_uuid/form/:uuid',
    name: '数据搜索',
    component: () => import('@/pages/form/SearchDatas.vue')
  },
  {
    path: '/showdata/:company_uuid/form/:uuid',
    name: '展示搜索结果',
    component: () => import('@/pages/form/showDatas.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/pages/exception/404.vue')
  },
  {
    path: '*',
    redirect: '/portal'
  }
]

const router = new Router({
  routes
})
export default router
