import AppComponents from './appComs/index'
import Text from './text/Text'
import Code from './code/Code'
import Title from './title/Title'
import Textarea from './textarea/Textarea'
import Warn from './warn/Warn'
import Date from './date/Date'
import City from './city/City'
import Countdown from './countdown/Countdown'
import Avatar from './avatar/Avatar'
import Carousel from './carousel/Carousel'
import selectData from './selectData/SelectData'
import video from './media/video/Video.vue'
import audio from './media/audio/Audio.vue'
const comments = [
  audio,
  video,
  selectData,
  Text,
  Code,
  Title,
  Textarea,
  Warn,
  Date,
  Carousel,
  City,
  Countdown,
  Avatar,
  ...AppComponents
]
const jUi = {
  install(Vue) {
    comments.forEach((comment) => {
      Vue.component(comment.name, comment)
    })
  }
}

export default jUi
