<template>
    <div class="selectData">
        <!-- 显示条件：不是表单设计并且f为true -->
        <!-- <a-select type="text" :value="dataModel[2]" @focus="showSelect" v-if="nextFormDesign !== '1' && f" :disabled="options.disabled">
        </a-select> -->
        <a-select
        @focus="focusData"
        :show-search="true"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        not-found-content="请输入内容搜索或未搜索到内容..."
        @search="search($event, options.compares)"
        @change="changeData"
        :allowClear="true"
        :value="dataStr"
        :dropdownMatchSelectWidth="false"
        :disabled="options.disabled"
        style="width: 100%"
        slot="suffixIcon">
            <a-select-option v-for="(item) in slectOptions" :key="item.id" :value="item.id">{{item.text}}</a-select-option>
        </a-select> &nbsp;
        <a-icon slot="suffixIcon" type="api" v-if="isShowIcon"/>
    </div>
</template>

<script>
    import {getFormDatas} from '@/services/form.js'
    import {mapMutations, mapState} from 'vuex'
    export default {
        name: 'jSelectData',
        props:{
            flag: {

            },
            dataModel: {

            },
            options: {
                type: Object
            },
            value: {
            },
            isShowIcon: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                nextFormDesign: '',
                f: true,
                dataStr: '',
                current: {},
                data: [],
                slectOptions: [],
                temp: ''
            }
        },
        created(){
            // if (this.options.compares.length < 2) {
            //     this.focusData()
                // console.log('this.options', this.options)
            // }
            this.nextFormDesign = localStorage.getItem('znextFormDesign')
            console.log('this.nextFormDesign', this.nextFormDesign)
        },
        computed: {
            ...mapState('self',['isEdit', 'elements'])
        },
        mounted(){
            this.$bus.$off(this.getEmitName(this.options.model))
            // 接收事件
            this.$bus.$on(this.getEmitName(this.options.model), val => {
				let {model, value} = val
                // console.log('val', val)
				this.options.compares.forEach(com => {
					if(com.model == model && model){
						com.valueField = value
					}
				})
                // this.search(null, this.options.compares)
            })
        },
        methods: {
            showSelect() {
                this.focusData()
                this.f = false
            },
            focusData() {
                if (Array.isArray(this.dataModel)) {
                    this.search(this.dataModel[2], this.options.compares)
                } else {
                    this.search('', this.options.compares)
                }
            },
            ...mapMutations('self',['setIsEdit']),
            getEmitName(basicName){
                if(this.options.isTable && this.options.rowId) {
                    return 'table' + this.options.rowId + basicName
                } else {
                    return basicName
                }
            }, 
            search(value, compare) {
                // console.log('value', value)
                // console.log('this.options', this.options)
                if(!this.options.formUuid || !this.options.showField || !this.options.valueField){
                    return
                }
                let field = [...this.options.assists];
                field.push(this.options.showField, this.options.valueField)
                let serachParams = {
                    uuid: this.options.formUuid,
                    data: field, // 根据传入的fd值去搜索
                    isTree: this.options.isTree, // 是否为树形展示
                    showField: this.options.showField // 
                }
                
                let compareArr = []
                
                if(compare?.comField && compare.valueField.length){
                    compareArr.push(compare)
                }
                if(Array.isArray(compare)) {
                    compare.forEach( res => {
                        if(res.comField && res.valueField){
                            compareArr.push(res)
                        }
                    })
                }
                if(compareArr.length > 0) {
                    serachParams.compare = compareArr
                }
                if(value){    //搜索
                    serachParams.field = value
                    serachParams.field_name = this.options.model   //field_name字段
                    let zTableUuid = localStorage.getItem('uuid')
                    serachParams.table_uuid = zTableUuid    //table_uuid字段
                    
                    if (value.length > 1) {
                        // console.log('传递的数据1', serachParams)
                        getFormDatas(serachParams).then(res => {
                        if(this.value[0]){
                            this.dataStr = this.value[0]
                        }
                        let {code, data} = res.data.data
                        if(code == 0){
                            this.data = data
                            const options = data.map(res => {
                                let text = Array.isArray(res[this.options.showField]) ? res[this.options.showField][2] : res[this.options.showField]
                                this.options.assists.forEach(ass => {
                                    if(Array.isArray(res[ass])){
                                        text += `[${res[ass][2]}]`
                                    } else {
                                        text += `[${res[ass].name || res[ass]}]`
                                    }
                                })
                                let value1 = res[this.options.valueField]
                                return {
                                    id: res.id,
                                    text,
                                    value: value1
                                }
                            });
                            this.slectOptions = options
                            let is = options.find(res => {
                                return res.id == this.value[0]
                            })
                            if(!is){
                                this.$emit('input', ['', '', ''])
                                this.dataStr = ''
                            }
                            this.changeData(this.dataStr)
                        } else if(code == 21) {
                            if(this.value[0]){
                                this.slectOptions = [{
                                    id: this.value[0],
                                    text: this.value[1],
                                    value: this.value[2]
                                }]
                                this.changeData(this.dataStr)
                            }
                        } else {
                            this.$message.error('【'+ this.options.label +'】字段的关联表单异常或已被删除，请重新关联')
                        }
                        })
                        }
                    return
                }
                serachParams.field_name = this.options.model    //field_name字段
                // console.log('this.options', this.options.model)
                let zTableUuid = localStorage.getItem('uuid')
                serachParams.table_uuid = zTableUuid    //table_uuid字段
                // console.log('传递的数据2', serachParams)
                // console.log('this.options.disabled', this.options.disabled)
                getFormDatas(serachParams).then(res => {
                    if(this.value[0]){
                        this.dataStr = this.value[0]
                    }
                    let {code, data} = res.data.data
                    if(code == 0){
                        this.data = data
                        const options = data.map(res => {
                            let text = Array.isArray(res[this.options.showField]) ? res[this.options.showField][2] : res[this.options.showField]
                            this.options.assists.forEach(ass => {
                                if(Array.isArray(res[ass])){
                                    text += `[${res[ass][2]}]`
                                } else {
                                    text += `[${res[ass].name || res[ass]}]`
                                }
                            })
                            let value1 = res[this.options.valueField]
                            return {
                                id: res.id,
                                text,
                                value: value1
                            }
                        });
                        this.slectOptions = options
                        let is = options.find(res => {
                            return res.id == this.value[0]
                        })
                        if(!is){
                            this.$emit('input', ['', '', ''])
                            this.dataStr = ''
                        }
                        this.changeData(this.dataStr)
                    } else if(code == 21) {
                        if(this.value[0]){
                            this.slectOptions = [{
                                id: this.value[0],
                                text: this.value[1],
                                value: this.value[2]
                            }]
                            this.changeData(this.dataStr)
                        }
                    } else {
                        this.$message.error('【'+ this.options.label +'】字段的关联表单异常或已被删除，请重新关联')
                    }
                })
            },
            changeData(value) {
                if(!value) {
                    this.dataStr = ''
                    this.$emit('input', ['', '', ''])
					this.options.relSelect.forEach(model => {
						this.$bus.$emit(this.getEmitName(model), [])
					})
                    return
                }
                this.dataStr = value
                this.getRel(value)
                this.text(value)
                let value1 = this.current[this.options.valueField]
                this.options.relSelect.forEach(res => {
                    this.$bus.$emit(this.getEmitName(res), {
                        model: this.options.model,
                        value: value1
                    })
                })
            },
            //获取关联取值
            getRel(id){
                this.current = this.data.find(res => {
                    return res.id == id
                })
                let changeData = {}
                const copyRelVals = JSON.parse(JSON.stringify(this.options.relVals))
                this.getFieldsTypeByFd(copyRelVals)
                copyRelVals.forEach(res => {
                    let value = this.current[res.field]
                    // 如果是关联下拉框 就取关联下拉框的第三个值（显示字段）
                    if(Array.isArray(value) && value.length == 3 && res.comType != 'selectData'){
                        value = value[2]
                    }
                    changeData[res.com] = value
                })
                // 如果当前关联下拉框属于子表中 那么他只能关联取值当前表内的字段
                if(this.options.isTable) {
                    this.$bus.$emit(this.options.isTable + 'em_relValue',  {index: this.options.index, datas: changeData})
                } else {
                    this.$bus.$emit('em_relValue',  changeData)
                }
            },
            //获取名字（把辅助显示排除掉）
            text(id){
                let findData = this.slectOptions.find(res => {
                    return res.id == id
                })
                if(!findData){
                    return
                }
                let selectCon = findData.text
                let end = selectCon.indexOf('[')
                if(end != -1){
                    this.dataStr = selectCon.substr(0, end)
                } else {
                    this.dataStr = selectCon
                }
                this.dataStr = this.dataStr.replaceAll('>', '')
                this.$emit('input', [id, this.current[this.options.valueField], this.dataStr])
            },
            // 获取关联取值的对应本表字段fd的类型
            getFieldsTypeByFd(relVals){
                this.elements.forEach(el => { 
                    if(el.type === 'panel') {
                        // console.log('111')
                    } else {
                        let find = relVals.find(res => {
                            return res.com === el.options.model
                        })
                        if(find){
                            find.comType = el.type
                        }
                    }
                })                
            }
        },
        watch:{
            value(newVal, oldVal){
                if(newVal[0] != oldVal[0]){
                    this.changeData(newVal[0])
                }
            }
        },
        destroyed(){
            this.$bus.$off(this.options.model)
        }
    }
</script>

<style lang="less" scoped>
    .selectData {
        display: flex;
        align-items: center;
    }
</style>